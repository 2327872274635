<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>
    <menu-vertical-config :is-drawer-open.sync="isDrawerOpen2"></menu-vertical-config>
    <Alert />
    <v-app-bar app flat fixed color="dark">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon class="d-block me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
          <select-img v-if="store.state.user.arbol.find(i => i.id_puesto >= 0) && $route.name.includes('puesto')">
          </select-img>
          <v-spacer></v-spacer>
          <!-- Right Content -->

          <ind-propina ref="IndPropinaRef"></ind-propina>
          <PruebaConexion />
          <v-btn @click="generarAlerta()" icon>
            <v-icon>{{ icons.mdiEmailFastOutline }}</v-icon>
          </v-btn>

          
            <v-btn @click="abrirChat()" icon>
              <v-badge overlap color="success" :content="chatSinleer"  :value="chatSinleer>0"  >
              <v-icon>{{ icons.mdiChatProcessingOutline }}</v-icon>
            </v-badge>
            </v-btn>
        

          <theme-switcher class="ms-3"></theme-switcher>
          <cambio-clave ref="CambioClaveRef" :tipoBtn="'0'"></cambio-clave>
          <AppBarNotifyMenu @GetConfirmacionUsuario="GetConfirmacionUsuario" />
          <app-bar-user-menu
            @AbrirQr="AbrirQr"
            @AbrirClave="AbrirClave"
            @AbrirPropina="AbrirPropina"
            @AbrirAvatar="AbrirAvatar"
            @AbrirConfig="AbrirConfig"
            @AbrirAutorizar="AbrirAutorizar"
          ></app-bar-user-menu>

          <ModalQrWifiVue ref="ModalQrWifiVueRef"></ModalQrWifiVue>
        </div>
      </div>
    </v-app-bar>

    <v-main v-if="store.state.infoCargaDatos.finalizado">
      <div class="app-content-container boxed-container" :class="$route.meta.full ? '' : 'pa-6'">
        <slot></slot>
      </div>
    </v-main>

    <avatar-select ref="AvatarSelectRef"></avatar-select>
    <ComporbacionUsuario ref="ComporbacionUsuarioRef" />

    <ModalConfirmarLeerQr ref="ModalConfirmarLeerQrRef"></ModalConfirmarLeerQr>
    <ModalEnviarAlerta ref="ModalEnviarAlertaRef"></ModalEnviarAlerta>

    <v-dialog v-model="dialogCargandoDatos" persistent width="300">
      <v-card :color="!store.state.infoCargaDatos.error ? 'primary' : 'error'" dark>
        <v-card-text>
          <div class="mb-4 text-center" v-if="store.state.infoCargaDatos.error">
            <v-icon class="white--text" size="100">{{ icons.mdiEmoticonCryOutline }}</v-icon>
          </div>
          <h3 class="mb-4 text-center">Carga de datos iniciales</h3>

          <v-divider></v-divider>
          <p class="white--text">
            <v-progress-circular
              size="20"
              indeterminate
              color="white"
              v-if="store.state.infoCargaDatos.sesion.cargando"
            ></v-progress-circular>
            <v-icon
              color="white"
              v-if="
                !store.state.infoCargaDatos.sesion.cargando &&
                !store.state.infoCargaDatos.sesion.finalizado & !store.state.infoCargaDatos.sesion.error
              "
              >{{ icons.mdiTimerSand }}</v-icon
            >

            <v-icon color="white" v-if="store.state.infoCargaDatos.sesion.error">{{ icons.mdiCloseCircle }}</v-icon>
            <v-icon color="white" v-if="store.state.infoCargaDatos.sesion.finalizado">{{
              icons.mdiCheckCircle
            }}</v-icon>
            Datos de sesion
          </p>
          <p class="white--text">
            <v-progress-circular
              size="20"
              indeterminate
              color="white"
              v-if="store.state.infoCargaDatos.version.cargando"
            ></v-progress-circular>
            <v-icon
              color="white"
              v-if="
                !store.state.infoCargaDatos.version.cargando &&
                !store.state.infoCargaDatos.version.finalizado & !store.state.infoCargaDatos.version.error
              "
              >{{ icons.mdiTimerSand }}</v-icon
            >

            <v-icon color="white" v-if="store.state.infoCargaDatos.version.error">{{ icons.mdiCloseCircle }}</v-icon>
            <v-icon color="white" v-if="store.state.infoCargaDatos.version.finalizado">{{
              icons.mdiCheckCircle
            }}</v-icon>
            Datos de version
          </p>
          <p class="white--text">
            <v-progress-circular
              size="20"
              indeterminate
              color="white"
              v-if="store.state.infoCargaDatos.datosGenerales.cargando"
            ></v-progress-circular>
            <v-icon
              color="white"
              v-if="
                !store.state.infoCargaDatos.datosGenerales.cargando &&
                !store.state.infoCargaDatos.datosGenerales.finalizado & !store.state.infoCargaDatos.datosGenerales.error
              "
              >{{ icons.mdiTimerSand }}</v-icon
            >

            <v-icon color="white" v-if="store.state.infoCargaDatos.datosGenerales.error">{{
              icons.mdiCloseCircle
            }}</v-icon>
            <v-icon color="white" v-if="store.state.infoCargaDatos.datosGenerales.finalizado">{{
              icons.mdiCheckCircle
            }}</v-icon>
            Datos de configuracion
          </p>
          <p class="white--text">
            <v-progress-circular
              size="20"
              indeterminate
              color="white"
              v-if="store.state.infoCargaDatos.puestoProductos.cargando"
            ></v-progress-circular>
            <v-icon
              color="white"
              v-if="
                !store.state.infoCargaDatos.puestoProductos.cargando &&
                !store.state.infoCargaDatos.puestoProductos.finalizado &
                  !store.state.infoCargaDatos.puestoProductos.error
              "
              >{{ icons.mdiTimerSand }}</v-icon
            >

            <v-icon color="white" v-if="store.state.infoCargaDatos.puestoProductos.error">{{
              icons.mdiCloseCircle
            }}</v-icon>
            <v-icon color="white" v-if="store.state.infoCargaDatos.puestoProductos.finalizado">{{
              icons.mdiCheckCircle
            }}</v-icon>
            Datos de productos
          </p>

          <p class="white--text text-center" v-if="store.state.infoCargaDatos.error">
            <v-divider></v-divider>
            Hubo un error al cargar los datos inciales. <br />
            Verifica su conexion a la red.
            <v-divider></v-divider>
            <v-btn small class="mt-2 secondary" @click="refreshPage()">Reintentar</v-btn>
          </p>
          <p class="white--text text-center" v-else>
            <v-divider></v-divider>
            Espere un momento.
          </p>
        </v-card-text>

        <v-progress-linear
          v-if="store.state.infoCargaDatos.cargando"
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogChat" persistent width="500" scrollable :fullscreen="$vuetify.breakpoint.mdAndDown"  >
      <Chat ref="chatRef" @cerrarModal="cerrarModal" :dialogChat="true" />
    </v-dialog>
  </v-app>
</template>

<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiBellOutline,
  mdiGithub,
  mdiCogOutline,
  mdiQrcode,
  mdiLanConnect,
  mdiLanDisconnect,
  mdiCloseCircle,
  mdiCheckCircle,
  mdiTimerSand,
  mdiEmoticonCryOutline,
  mdiChatProcessingOutline,
} from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import AppBarNotifyMenu from './components/AppBarNotifyMenu.vue'

import SelectImg from './components/SelectImg.vue'
import store from '@/store'
import router from '@/router'
import axios from '@/api'
import Alert from '@/layouts/Alert.vue'
import MenuVerticalConfig from './components/MenuVerticalConfig.vue'
import IndPropina from '@/views/sistema/comanda/dashboard/componentes/IndPropina.vue'
import CambioClave from '@/views/sistema/administracion/archivo/empleado/CambioClave.vue'
import MaestroService from '@/api/servicios/MaestroService'
import AvatarSelect from '@/components/AvatarSelect.vue'
import ProductosService from '@/api/servicios/ProductosService'
import ModalQrWifiVue from '@/components/ModalQrWifi.vue'
import PruebaConexion from '@/components/PruebaConexion.vue'
import { socket, state as stateSocket } from '@/plugins/sockets'
import ComporbacionUsuario from '../components/ComporbacionUsuario.vue'
import { acciones } from '@/modulos'
import ModalConfirmarLeerQr from '@/components/ModalConfirmarLeerQr.vue'
import ModalEnviarAlerta from '../components/ModalEnviarAlerta.vue'
import funciones from '@/funciones/funciones'
import Chat from '@/views/sistema/chat/Chat.vue'
export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    AppBarNotifyMenu,
    SelectImg,
    Alert,
    MenuVerticalConfig,
    IndPropina,
    CambioClave,
    AvatarSelect,
    ModalQrWifiVue,
    PruebaConexion,
    ComporbacionUsuario,
    ModalConfirmarLeerQr,
    ModalEnviarAlerta,
    Chat,
  },
  methods: {
    refreshPage() {
      location.reload()
    },
  },
  watch: {
    '$store.state.infoCargaDatos.cargando': function (nuew, old) {
      if (this.store.state.infoCargaDatos.finalizado == true) {
        this.dialogCargandoDatos = false
      } else {
        this.dialogCargandoDatos = true
      }
    },
  },
  setup() {
    const dialogChat = ref(false)
    const dialogCargandoDatos = ref(true)
    const ruta = ref('')
    const mdiEmailFastOutline =
      'M22 5.5H9C7.9 5.5 7 6.4 7 7.5V16.5C7 17.61 7.9 18.5 9 18.5H22C23.11 18.5 24 17.61 24 16.5V7.5C24 6.4 23.11 5.5 22 5.5M22 16.5H9V9.17L15.5 12.5L22 9.17V16.5M15.5 10.81L9 7.5H22L15.5 10.81M5 16.5C5 16.67 5.03 16.83 5.05 17H1C.448 17 0 16.55 0 16S.448 15 1 15H5V16.5M3 7H5.05C5.03 7.17 5 7.33 5 7.5V9H3C2.45 9 2 8.55 2 8S2.45 7 3 7M1 12C1 11.45 1.45 11 2 11H5V13H2C1.45 13 1 12.55 1 12Z'
    const ModalEnviarAlertaRef = ref()
    const AvatarSelectRef = ref(null)
    const AvatarSelectNotifyRef = ref(null)
    const isDrawerOpen = ref(false)
    const isDrawerOpen2 = ref(null)
    const ModalQrWifiVueRef = ref(null)
    const ComporbacionUsuarioRef = ref(false)
    const AbrirAvatar = () => {
      AvatarSelectRef.value.abrir()
    }

    const cerrarModal = () => {
      dialogChat.value = false
    }

    const generarAlerta = () => {
      ModalEnviarAlertaRef.value.abrirModal()
    }

    const chatRef = ref()
    const abrirChat = () => {
      chatSinleer.value =0
      dialogChat.value = true
      chatRef.value.cargarChat()
    }

    const AbrirConfig = () => {
      isDrawerOpen2.value = true
    }

    let counterConsultas = ref(0)
    /*watch(counterConsultas, () => {
      setTimeout(() => {
        CargarPuestoProductos(true)
      }, 30000)
    })*/
    /*
    onBeforeMount(() => {
      setTimeout(() => {
        CargarPuestoProductos(false)
        cargarVersion()
      }, 1000)
    })*/
    const chatSinleer = ref(0)
    onBeforeMount(() => {
      socket.on('modifica-producto', (...args) => {
        CargarPuestoProductos(false)
      })

      socket.on('nuevo_chat', (...args) => {
        if (dialogChat.value == true) {
          chatRef.value.recibirChat(args)
        } else {  
          store.commit('setAlert', {
            titulo:args[0].contacto,
            message: args[0].mensaje,
            icon: mdiChatProcessingOutline,
            type: 'info', 
          })
          chatSinleer.value += 1
          notifySonido()
        }
      })
    })

    const cargarVersion = async () => {
      store.commit('setinfoCargaDatos', {
        tipo: 'version',
        datos: { cargando: true, finalizado: false, error: false },
      })

      await MaestroService.versionSistemaActual({ actual: process.env.VUE_APP_VERSION })
        .then(response => {
          if (response.data.mensaje == 'ERROR') {
            store.commit('setinfoCargaDatos', {
              tipo: 'version',
              datos: { cargando: false, finalizado: false, error: true },
            })

            store.commit('setUserData', { autenticate: false })
            router.push('/pages/login')
          } else {
            store.commit('setinfoCargaDatos', {
              tipo: 'version',
              datos: { cargando: false, finalizado: true, error: false },
            })
            DatosConfiguracion()
          }
        })
        .catch(error => {
          store.commit('setinfoCargaDatos', {
            tipo: 'version',
            datos: { cargando: false, finalizado: false, error: true },
          })
          store.commit('setAlert', {
            message: error,
            type: 'error',
            error: {
              ...error,
              response: error?.response?.data,
            },
            funcion: 'cargarVersion',
          })
        })
    }

    const DatosConfiguracion = () => {
      store.commit('setinfoCargaDatos', {
        tipo: 'datosGenerales',
        datos: { cargando: true, finalizado: false, error: false },
      })
      MaestroService.DatosConfiguracion()
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            store.commit('setconfiguracionGeneral', response.data.datos)
            store.commit('setinfoCargaDatos', {
              tipo: 'datosGenerales',
              datos: { cargando: false, finalizado: true, error: false },
            })
            CargarPuestoProductos(false)
          }
        })
        .catch(error => {
          store.commit('setinfoCargaDatos', {
            tipo: 'datosGenerales',
            datos: { cargando: false, finalizado: true, error: false },
          })

          store.commit('setAlert', {
            message: error,
            type: 'error',
            error: {
              ...error,
              response: error?.response?.data,
            },
            funcion: 'DatosConfiguracion',
          })
        })
    }
    const linkMaster = () => {
      return config.VUE_APP_URL_IMG || process.env.VUE_APP_URL_IMG
    }
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const CargarPuestoProductos = actualizar => {
      if (store.state.puestoProductos.length > 0) {
        actualizar = false
      } else {
        actualizar = true
      }

      if (actualizar) {
        store.commit('setinfoCargaDatos', {
          tipo: 'puestoProductos',
          datos: { cargando: true, finalizado: false, error: false },
        })
      } else {
        store.commit('setinfoCargaDatos', {
          tipo: 'puestoProductos',
          datos: { cargando: false, finalizado: true, error: false },
        })
      }
      if (funciones.getNavegador() != 'safari') {
        if (validarAcciones(acciones.DASHBOARD_COMANDA_VER_COMANDA)) {
          ProductosService.obtenerProductosPuestos({ id_puesto: -1 })
            .then(response => {
              if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
                store.commit('setPuestoProductos', response.data.datos)
                if (actualizar) {
                  store.commit('setinfoCargaDatos', {
                    tipo: 'puestoProductos',
                    datos: { cargando: false, finalizado: true, error: false },
                  })
                }
              }
            })
            .catch(error => {
              if (actualizar) {
                store.commit('setinfoCargaDatos', {
                  tipo: 'puestoProductos',
                  datos: { cargando: false, finalizado: false, error: true },
                })
              }

              console.error(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
                error: {
                  ...error,
                  response: error?.response?.data,
                },
                funcion: 'CargarPuestoProductos',
              })
            })
        } else {
          store.commit('setinfoCargaDatos', {
            tipo: 'puestoProductos',
            datos: { cargando: false, finalizado: true, error: false },
          })
        }
      } else {
        store.commit('setinfoCargaDatos', {
          tipo: 'puestoProductos',
          datos: { cargando: false, finalizado: true, error: false },
        })
      }
    }

    const GetConfirmacionUsuario = args => {
      if (args.usuario.toUpperCase() == store.state.user.usuario) {
        ComporbacionUsuarioRef.value.abrir(args)
      }
    }

    if (!store.state.user.IsAutenticated) {
      router.push('/pages/login')
    } else {
      if (axios.defaults.headers.common['Authorization'] == undefined) {
        store.commit('setinfoCargaDatos', {
          tipo: 'sesion',
          datos: { cargando: true, finalizado: false, error: false },
        })

        console.log(axios.defaults.headers.common['Authorization'])
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token

        axios
          .post('/login/iniciarSesionToken', {
            user: store.state.user.id,
            token: store.state.user.token,
          })
          .then(function (response) {
            if (response.data.mensaje == 'INICIO_SESION_EXITOSA') {
              store.commit('setUserData', { ...response.data.datos, autenticate: true })
              store.commit('setinfoCargaDatos', {
                tipo: 'sesion',
                datos: { cargando: false, finalizado: true, error: false },
              })
              cargarVersion()

              socket.emit('join', { usuario: store.state.user.usuario })
            } else {
              store.commit('setinfoCargaDatos', {
                tipo: 'sesion',
                datos: { cargando: false, finalizado: false, error: true },
              })
              store.commit('setUserData', { autenticate: false })
              router.push('/pages/login')
            }
            console.log(response)
          })
          .catch(function (error) {
            store.commit('setinfoCargaDatos', {
              tipo: 'sesion',
              datos: { cargando: false, finalizado: false, error: true },
            })

            if (error.response.status == 403) {
              store.commit('setUserData', { autenticate: false })
              router.push('/pages/login')
            }
            console.error('aler', error)
            store.commit('setAlert', {
              message: error,
              type: 'error',
              error: error,
              funcion: 'iniciarSesionToken',
            })
          })
      } else {
        store.commit('setinfoCargaDatos', {
          tipo: 'sesion',
          datos: { cargando: false, finalizado: true, error: false },
        })
        cargarVersion()
      }
    }

    const AbrirQr = () => {
      ModalQrWifiVueRef.value.abrir()
    }
    const CambioClaveRef = ref(null)
    const AbrirClave = () => {
      CambioClaveRef.value.abrir()
    }

    const IndPropinaRef = ref(null)
    const AbrirPropina = () => {
      IndPropinaRef.value.abrir()
    }
    const ModalConfirmarLeerQrRef = ref()
    const AbrirAutorizar = () => {
      ModalConfirmarLeerQrRef.value.abrir()
    }


    const notifySonido = (title, body) => {
      let url = null
      
     
        url = require('@/assets/sonidos/chat.mp3')
     

      var audio = new Audio(url)
      audio.play()
    }
    return {
      isDrawerOpen,
      isDrawerOpen2,
      AbrirConfig,
      CargarPuestoProductos,
      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiCogOutline,
        mdiQrcode,
        mdiLanConnect,
        mdiLanDisconnect,
        mdiEmailFastOutline,
        mdiCloseCircle,
        mdiCheckCircle,
        mdiTimerSand,
        mdiEmoticonCryOutline,
        mdiChatProcessingOutline,
      },
      ComporbacionUsuarioRef,
      store,
      AbrirAvatar,
      AvatarSelectRef,
      ModalQrWifiVueRef,
      AbrirQr,
      linkMaster,
      AbrirClave,
      AbrirPropina,
      IndPropinaRef,
      CambioClaveRef,
      GetConfirmacionUsuario,
      ModalConfirmarLeerQrRef,
      AbrirAutorizar,
      generarAlerta,
      ModalEnviarAlertaRef,
      dialogCargandoDatos,
      dialogChat,
      abrirChat,
      cerrarModal,
      chatRef,
      chatSinleer,
      notifySonido
    }
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  margin-left: auto;
  margin-right: auto;
}
</style>

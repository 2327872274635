<template>
  <span>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Enviar Alerta </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete hide-details="auto" outlined dense item-value="id" item-text="nombre"  v-model="empleado" :items="empleados" label="Empleado">
                <template v-slot:no-data>
                    <v-list-item>
                    <v-list-item-title> No hay empleado conectados </v-list-item-title>
                    </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                    <span>{{ item.nombre }} {{ item.apellido }} </span>
                </template>
                <template v-slot:item="{ item }">
                    <v-list-item-content>
                    <v-list-item-title> {{ item.nombre }} {{ item.apellido }} </v-list-item-title>
                    </v-list-item-content>
                </template>
            </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-select  hide-details="auto" outlined dense v-model="prioridad" item-value="color" item-text="descripcion"  :items="prioridades" label="Prioridad"></v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea 
              hide-details="auto"
              outlined dense
                label="Mensaje"
                value="" 
                v-model="mensaje"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false"> cerrar </v-btn>
          <v-btn color="primary" @click="generarAlerta()"> Enviar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { socket, state as stateSocket } from '@/plugins/sockets'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import store from '@/store'
import funciones from '@/funciones/funciones'
export default {
  setup(props) {

    const abrirModal =()=>{
        dialog.value = true
        consultarUsuarios()
    }

    socket.on('listar-conectados', args => {
        empleados.value = args
    })

    socket.on('alert-confirmado', args => {
       
        store.commit('setAlert', {
              message: `el usuario ${args.datos.empleado.nombre} ${args.datos.empleado.apellido} confirmó la alerta: ${args.datos.sub_titulo}`,
              type: 'success',
            })
 
        console.log(args)  
    })

    socket.on('alert-cancelado', args => {
        
        store.commit('setAlert', {
              message: `el usuario ${args.datos.empleado.nombre} ${args.datos.empleado.apellido} rechazó la alerta: ${args.datos.sub_titulo}`,
              type: 'warning',
            })
        console.log(args)
    })

    const dialog = ref(false)
    const empleados = ref([])
    const empleado= ref({})
    const mensaje = ref('')
    const prioridad = ref({})

    const prioridades = ref([
    { color: 'secondary', descripcion: 'Normal' },
    { color: 'warning', descripcion: 'Media' },
    { color: 'error', descripcion: 'Alta' }

    ])
    
    const consultarUsuarios = () => {
      socket.emit('listar-conectados',  {})
    }

    const limpiar=()=>{
        empleado.value = ''
        mensaje.value = ''
        prioridad.value= ''
    }

    const generarAlerta = () => {
      try {
       
     
      socket.emit('alerta', {
        id_usuario_receptor: empleado.value,
        sub_titulo: mensaje.value,
        color: prioridad.value,
        idTran: funciones.getRanHex(12),
        empleado: {
          id: store.state.user.id,
          nombre: store.state.user.usuario,
          nombre_empleado: store.state.user.nombre,
          apellido_empleado: store.state.user.apellido,
        },  
      })

      
      limpiar()
      dialog.value = false
      store.commit('setAlert', {
              message: `Se envio la alerta correctamente.`,
              type: 'success',
            })


    }catch(e){
        store.commit('setAlert', {
              message: `hubo un error al enviar la alerta`,
              type: 'error',
               error:err,
              funcion:"generarAlerta mo"
            })
      }
    }

    return {
      dialog,
      generarAlerta,
      empleados,
      prioridades,
      mensaje,
      empleado,
      prioridad,
      abrirModal,
      store
    }
  },
}
</script>

<style>
</style>



import { read, utils, writeFile } from 'sheetjs-style'
import moment from 'moment/moment';
import store from '@/store'
class FuncionesGenerales {


    configuracionSelect(_id) {
        let valor = null
        if (store.state.configuracionGeneral?.configuraciones) {


            store.state.configuracionGeneral?.configuraciones.forEach(element => {
                if (_id == element.id) {
                    valor = element.valor
                }
            })
        }

        return valor
    }

    truncarDecimal(numero, posiciones = 0) {
        const factor = 10 ** posiciones;
        return Math.trunc(numero * factor) / factor;
    }
    fechasHorarioOficina() {
        if (this.configuracionSelect(14)) {
            const horario = this.configuracionSelect(14).split('-')
            const hora_actual = moment(store.state.horaActual)
            const hora_inicio = moment(moment(store.state.horaActual).format('YYYY-MM-DD') + ' ' + horario[0] + ':00:00')
            const hora_fin = moment(moment(store.state.horaActual).format('YYYY-MM-DD') + ' ' + horario[1] + ':00:00')

            return (hora_actual >= hora_inicio && hora_actual <= hora_fin)
        } else {
            return true
        }
    }


    roundToTwo = (num, precicion = '2') => {
        return (Math.round(num + "e+" + precicion) + "e-" + precicion);
    }


    roundTo = (num, precicion = '2') => {
        return Number((Math.round(num + "e+" + precicion) + "e-" + precicion)).toFixed(precicion);
    }
    equalNumber = (x, y) => {
        return Math.abs(x - y) < Number.EPSILON;
    }

    formatoFecha = (fecha, tipo = 1) => {
        //tipo 1 solo fecha 10/10/2010
        //tipo 2 solo hora 02:02:00 pm
        //tipo 3 hora y fecha 10/10/2010 02:02:00 pm
        //tipo 4 hora y fecha 2000-10-01


        if (tipo == 1) {
            return moment(fecha).format("DD/MM/YYYY")   // new Date(fecha).toLocaleDateString('es-ve', { day: "2-digit", month: "2-digit", year: "numeric" })
        } else if (tipo == 2) {
            return moment(fecha).format("h:mm:ss a") //new Date(fecha).toLocaleTimeString('es-ve', { hour: "2-digit", minute: "2-digit", second: "2-digit" })
        } else if (tipo == 3) {
            return moment(fecha).format("DD/MM/YYYY, h:mm:ss a") //new Date(fecha).toLocaleDateString('es-ve', { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" })
        } else if (tipo == 4) {
            return moment(fecha).format("YYYY-MM-DD") //new Date(fecha).toLocaleDateString("fr-CA", { day: "2-digit", month: "2-digit", year: "numeric" })

        } else {
            return moment(fecha).format("DD/MM/YYYY, h:mm:ss a")  //new Date(fecha).toLocaleDateString('es-ve', { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" })
        }

    }

    camelCase = (textos, SoloPrimero = false) => {
        if (textos != undefined && textos != null && textos != '') {
            const arr = textos?.toLowerCase().split(" ")
            var text = ""
            console.log(text)
            console.log(arr)
            arr.forEach((element, i) => {
                if (i == 0) {
                    if (element.toString().length > 0) {
                        text = element[0].toUpperCase() + element.slice(1);
                    }
                } else {
                    if (!SoloPrimero) {
                        if (element.toString().length) {
                            text = text + " " + element[0].toUpperCase() + element.slice(1);
                        }

                    } else {
                        text = text + " " + element;
                    }
                }

            });
            return text
        } else {
            return 'Null'
        }

    }

    exportarDataExcelVariasHojas = (datos) => {

        const wb = utils.book_new();
        const format = {
            font: {
                bold: true,
            },
            alignment: {
                horizontal: "center",
                vertical: "center"
            },
        }


        datos.hojas.forEach(hoja => {
            //////CABECERA//////
            let ws_data = []
            let cabecera = []
            hoja.cabecera.forEach(item => {
                cabecera.push({ v: item.toUpperCase(), s: format })
            })

            ws_data.push(cabecera)
            ws_data = ws_data.concat(hoja.rows)

            // let ws = utils.aoa_to_sheet(ws_data);
            const ws = utils.aoa_to_sheet(ws_data);
            ws["!cols"] = hoja.anchoColumanas;
            utils.book_append_sheet(wb, ws, hoja.nombreHoja);
        })

        writeFile(wb, datos.nombreDocumento + '.xlsx');

    }



    recorrerObjeto = (objeto) => {
        var stringFiltro = ""
        Object.keys(objeto).forEach(function (key) {
            if (objeto[key]) {
                console.log('Key : ' + key + ', Value : ' + objeto[key])
                if (stringFiltro === "") {
                    stringFiltro = key + '=' + objeto[key]
                } else {
                    stringFiltro += '&' + key + '=' + objeto[key]
                }
            }

        })
        return stringFiltro;
    }
    diferenciasHoras = (desde, hasta) => {
        var a = new Date(desde);
        var b = new Date(hasta);
        //La diferencia se da en milisegundos así que debes dividir entre 1000
        var c = ((a - b) / 1000);
        console.log(c / 60) // resultado 5;
        return (c / 60).toFixed()
    }

    formatoNumeric = (number, frac = 2) => {
        return new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: frac,
            maximumFractionDigits: frac,
        }).format(number)
    }

    exportarDataExcel = (datos) => {
        console.log(datos)
        const wb = utils.book_new();
        const format = {
            font: {
                bold: true,
            },
            alignment: {
                horizontal: "center",
                vertical: "center"
            },


        }

        //////CABECERA//////
        let ws_data = []
        let cabecera = []
        datos.cabecera.forEach(item => {
            cabecera.push({ v: item.toUpperCase(), s: format })
        })

        ws_data.push(cabecera)
        ws_data = ws_data.concat(datos.rows)

        // let ws = utils.aoa_to_sheet(ws_data);
        const ws = utils.aoa_to_sheet(ws_data);
        ws["!cols"] = datos.anchoColumanas;
        utils.book_append_sheet(wb, ws, datos.nombreHoja);

        writeFile(wb, datos.nombreDocumento + '.xlsx');

    }


    aleatorio = (inferior, superior) => {
        var numPosibilidades = superior - inferior;
        var aleatorio = Math.random() * (numPosibilidades + 1);
        aleatorio = Math.floor(aleatorio);
        return inferior + aleatorio;
    }


    getRanHex = size => {
        let result = [];
        let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

        for (let n = 0; n < size; n++) {
            result.push(hexRef[Math.floor(Math.random() * 16)]);
        }
        return result.join('');
    }

    getMovil = () => {
        if (navigator.userAgent.indexOf("Win") != -1)

            return "windows";
        if (navigator.userAgent.indexOf("Mac") != -1)
            return "macintosh";
        if (navigator.userAgent.indexOf("Linux") != -1)
            return "linux";
        if (navigator.userAgent.indexOf("Android") != -1)
            return "android";
        if (navigator.userAgent.indexOf("like Mac") != -1)
            return "ios";

        return "otro"

    }
    getBrowserInfo = () => {
        var ua = navigator.userAgent, tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edg)\/(\d+)/);
            if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    };
    getNavegador = () => {
        try {



            let browser = 'as'
            let usrAg = this.getBrowserInfo().toLowerCase();

            if (usrAg.indexOf("edg") > -1) {

                browser = "edge";
            }
            else if (usrAg.indexOf("safari") > -1) {

                browser = "safari";
            }
            else if (usrAg.indexOf("chrome") > -1) {

                browser = "chrome";
            }
            else if (usrAg.indexOf("opera") > -1) {

                browser = "opera";
            }
            else if (usrAg.indexOf("firefox") > -1) { browser = "firefox"; }

            else if (usrAg.indexOf("msie") > -1) { browser = "explorer"; }
            else {

                browser = "desconocido";
            }


            return browser


        } catch (error) {

            console.log('navegador desconocido', error)

            return "Desconocido"
        }

    }
}

export default new FuncionesGenerales();
<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Escanea QR </v-card-title>

        <v-card-text>
          <StreamBarcodeReader v-if="cerraCamara" @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
         
        </v-card-text>
        <v-card-text v-if="idTran" class="text-center">
          <h4>Enviado</h4>
          <h3  class="text-center">{{ idTran }}</h3>
          <v-icon size="100" color="success">{{ icons.mdiCheckCircleOutline }}</v-icon>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small v-if="idTran" @click="cerraCamara = true" class="primary">Escanear Nuevo</v-btn>
          <v-btn small color="secondary" @click="pararCamara()"> cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
import { StreamBarcodeReader } from 'vue-barcode-reader'
import { ref } from '@vue/composition-api'
import { socket, state as stateSocket } from '@/plugins/sockets'
import store from '@/store'
import { mdiCheckCircleOutline, mdiCancel } from '@mdi/js'
export default {
  components: {
    StreamBarcodeReader,
  },
  methods: {
    pararCamara() {
      /* this.$refs.scanner.codeReader.stream.getTracks().forEach(function (track) {
        track.stop()
      })*/
      this.cerraCamara = false
      this.dialog = false
    },
    stopBarcodeScanner() {
      // Suponiendo que el componente StreamBarcodeReader tiene un método stop()
      // que detiene el stream del lector de códigos de barras
      this.$refs.scanner.codeReader.stream.getTracks().forEach(function (track) {
        track.stop()
      })
    },
  },
  beforeDestroy() {
    this.stopBarcodeScanner()
  },
  setup() {
    const idTran = ref('')
    const cerraCamara = ref(false)
    const dialog = ref(false)
    const onDecode = async _dato => {
      console.log('onDecode', _dato)
      idTran.value = _dato

      await socket.emit('autorizar-qr', {
        idTran: idTran.value,
        id_empleado: store.state.user.id,
        usuario: store.state.user.usuario,
      })

      if (idTran.value) {
        cerraCamara.value = false
      }

      setTimeout(function () {
        //context.emit('GetConfirmacionUsuario', funcion, datosFuncion, funcionCancelar, true)
        dialog.value = false
      }, 1000)
    }
    const onLoaded = () => {
      console.log('onLoaded')
    }

    const abrir = () => {
      dialog.value = true
      cerraCamara.value = true
      idTran.value = ''
    }

    return {
      dialog,
      abrir,
      onDecode,
      onLoaded,
      cerraCamara,
      idTran,
      icons:{
        mdiCheckCircleOutline, mdiCancel
      }
    }
  },
}
</script>

<template>
  <div>
    <v-btn small color="primary" dark @click="dialog = true" v-if="store.state.puestoSelect">
      {{ store.state.puestoSelect.nombre }}
      <v-icon dark right>
        {{ icons.mdiCheckboxMarkedCircleOutline }}
      </v-icon>
    </v-btn>

    <v-dialog v-model="dialog" width="500" scrollable>
      <v-card>
        <v-card-title class="lighten-2">
          <v-row>
            <v-col cols="12">
              <span>Seleccione una sucursal </span>
            </v-col>
            <v-col cols="12">
              <v-text-field dense outlined label="Buscar" v-model="filter"></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text style="height: 300px">
          <v-container>
            <v-row>
              <v-col v-for="(n,index) in FiltrarSucursales()" :key="index" cols="12" sm="6" md="4">
                <v-card
                  class="d-flex align-center"
                  :class="n.id == store.state.puestoSelect.id ? 'primary' : ''"
                  dark
                  height="100"
                  @click="SelectImagen(n)"
                >
                  <v-scroll-y-transition>
                    <span class="flex-grow-1 text-center white--text">{{ n.nombre }}</span>
                  </v-scroll-y-transition>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiCheckboxMarkedCircleOutline,
  mdiCancel,
  mdiMinusCircleOutline,
  mdiArrowLeft,
  mdiWrenchOutline,
  mdiCloudUploadOutline,
} from '@mdi/js'
import store from '@/store'
import router from '@/router'
import Vue from 'vue'
export default {
  setup() {
    const friends = ref(['Sandra Adams', 'Britta Holt'])

    let itemSelect = ref({ title: 'COPALOCA', avatar: require('@/assets/images/avatars/1.png') })
    const items = ref([
      { title: 'COPALOCA', avatar: require('@/assets/images/avatars/1.png') },
      { title: 'KFE', avatar: require('@/assets/images/avatars/1.png') },
      { title: 'FUNKART', avatar: require('@/assets/images/avatars/1.png') },
    ])

    const people = ref([
      { name: 'Sandra Adams', avatar: require('@/assets/images/avatars/1.png') },
      { name: 'Ali Connors', avatar: require('@/assets/images/avatars/1.png') },
      { name: 'Trevor Hansen', avatar: require('@/assets/images/avatars/1.png') },
      { name: 'Tucker Smith', avatar: require('@/assets/images/avatars/1.png') },
      { name: 'Britta Holt', avatar: require('@/assets/images/avatars/1.png') },
    ])
    const dialog = ref(false)
    const filter = ref('')
    const FiltrarSucursales = () => {
  
      return store.state.user.arbol.filter(
        item =>
          item.id_puesto >= 0 &&
          (item.nombre.toUpperCase().includes(filter.value.toUpperCase()) ||
            filter.value == '' ||
            filter.value == undefined ||
            filter.value == null),
      )
    }

    const SelectImagen = item => {
      if (item.id != store.state.puestoSelect.id_puesto) {
        Vue.swal({
          icon: 'warning',
          html:
            '<h3>¿Seguro que quiere cambiar de area ? </h3>' +
            `<p># ${item.nombre}</p> <br>` +
            `<small>Si tiene algun documento abierto se perderá.</small> `,
          showCancelButton: true,
          confirmButtonText: 'Si, Seguro',
          showLoaderOnConfirm: true,
          cancelButtonText: 'No',
          preConfirm: () => {
            store.commit('setPuestoSelect', item)
            dialog.value = false
          },
          allowOutsideClick: () => !Vue.swal.isLoading(),
        })
      }
    }

    return {
      SelectImagen,
      store,
      itemSelect,
      items,
      icons: {
        mdiCheckboxMarkedCircleOutline,
        mdiCancel,
        mdiMinusCircleOutline,
        mdiArrowLeft,
        mdiWrenchOutline,
        mdiCloudUploadOutline,
      },
      FiltrarSucursales,
      dialog,
      filter,
    }
  },
}
</script>


import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios from '@/api'
import moment from 'moment'
import { acciones } from '@/modulos'
import { socket, state as stateSocket } from '@/plugins/sockets'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({ 
    puestoProductos:   state.puestoProductos,
    user: state.user,
    impresoraFiscal: state.impresoraFiscal,
    tikera:  state.tikera,
    copiasTickera: state.copiasTickera,
    llamarMesoneroMesa : state.llamarMesoneroMesa,
    comandaActual : state.comandaActual,
    configuracionGeneral: state.configuracionGeneral,
    puestoSelect:state.puestoSelect,
    documentosIniciados : state.documentosIniciados,
    impresoraFiscalSelect: state.impresoraFiscalSelect,
    impresoraFiscalTipo: state.impresoraFiscalTipo

   })
})

export default new Vuex.Store({
  state: {
    infoCargaDatos:{
        cargando: null,
        sesion:{
          cargando: false,
          finalizado:false,
          error:false
        },
        version:{
          cargando: false,
          finalizado:false,
          error:false
        },
        datosGenerales:{
          cargando: false,
          finalizado:false,
          error:false
        },
        puestoProductos:{
          cargando: false,
          finalizado:false,
          error:false
        },
        error:false,
        finalizado: false
    },
    user: {
      userName: '',
      IsAutenticated: false,
      accion_modulo: '[ ]',
      age: 25,
      apellido: '',
      arbol: [],
      autenticate: true,
      caja: {},
      foto: '',
      id: 0,
      id_empleado: 0,
      modulo: '[]',
      nombre: '',
      puestos: [],
      token: '',
      usuario: '',
      zonas:''
    },
    puestoSelect: {},
    alert: {},
    dark: false,
    tikera: {
      id: 1,
      nombre: "comanda 1"
    },
    copiasTickera: "0",
    impresoraFiscal: {
      modeloImpresora: 1,
      puerto: 99,
      bAudios: 9600,
      paridad: 2,
      datos: 8,
      stopBits: 1,
      handshake: 0,
      maxTiempoEspera: 5000,
      tiempoAntesLectura: 100,
    },
    impresoraFiscalSelect:{},
    impresoraFiscalTipo:"LOCAL",
    configuracionGeneral: {},
    puestoProductos: [],
    horaActual: "",
    puntoVentaFavorito: [],
    isConnected: false,
    socketMessage: "",
    comandaActual:{},
    llamarMesoneroMesa:{},
    documentosIniciados:{
      conteo:{},
      ajuste:{}
    },
    notaCreditoCompra:{}
  },
  mutations: {
    setAlert(state, datos) {
      state.alert = datos
    },
    setNotaCreditoCompra(state, datos) {
      state.notaCreditoCompra = datos
    },
    setUserData(state, datos) {

      const tempPuestoProductos = state.puestoProductos
      state.puestoProductos = []

      if (datos.IsAutenticated) {


        const validarAcciones = accion => {
          const valor = state.user.accion_modulo.includes(accion)

          return valor
        }

        state.user = datos;
        if (datos.arbol != undefined && datos.arbol.filter((i) => (i.id_puesto >= 0))) {
          if (state.puestoSelect?.id_puesto == undefined) {

            state.puestoSelect = datos.arbol.filter((i) => (i.id_puesto >= 0))[0]
          }
          else if (
            datos.arbol.filter((i) => (i.id_puesto >= 0 && state.puestoSelect?.id_puesto == i.id_puesto)).length == 0

          ) {

            state.puestoSelect = datos.arbol.filter((i) => (i.id_puesto >= 0))[0]
          } else {
            console.log(datos.arbol.filter((i) => (i.id_puesto >= 0 && state.puestoSelect.id_puesto == i.id_puesto)))
            console.log(datos.arbol.filter((i) => (i.id_puesto >= 0 && state.puestoSelect.id_puesto == i.id_puesto)).length)

          }
          if (validarAcciones(acciones.DASHBOARD_COMANDA_VER_COMANDA)) {
            state.puestoProductos = tempPuestoProductos

          }

        }

        const token = datos.token
        if (token) {
          console.log("creo Authorization")
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        } else {
          delete axios.defaults.headers.common["Authorization"];
          console.log("borro Authorization")
        }
      } else {
        if (state.user.usuario) {
          socket.emit('join-delete', { id_usuario: state.user.usuario })
        }

        state.user = datos;
        delete axios.defaults.headers.common["Authorization"];
        console.log("borro Authorization")
      }
    },
    setPuestoSelect(state, objeto) {
      state.puestoSelect = objeto;
    },
    setPuntoVentaFavorito(state, objeto) {
      state.puntoVentaFavorito.push(objeto);
    },
    deletePuntoVentaFavorito(state, objeto) {
      state.puntoVentaFavorito = state.puntoVentaFavorito.filter(i => i != objeto);
    },
    setPuestoProductos(state, datos) {
      state.puestoProductos = datos;
    },
    seTikera(state, datos) {
      state.tikera = datos
    },
    setImpresoraFiscal(state, datos) {
      state.impresoraFiscal = datos
    },
    setImpresoraFiscalSelect(state, datos) {
      state.impresoraFiscalSelect = datos
    },
    setImpresoraFiscalTipo(state, datos) {
      state.impresoraFiscalTipo = datos
    },
    setDark(state, datos) {
      state.dark = datos
    },
    setconfiguracionGeneral(state, datos) {
      state.configuracionGeneral = datos
    },
    setHoraActual(state, datos) {
      state.horaActual = datos
    },
    SetCopiaTicket(state, datos) {
      state.copiasTickera = datos
    },
    setSegundoHoraActual(state, datos) {

      var date = moment(state.horaActual)
        .add(datos, 'seconds')
      state.horaActual = date
    },
    setComandaActual(state, datos) { 
      state.comandaActual = datos
    },
    setUsuarioZona(state, datos) { 
      state.user.zonas = datos
    },
    setLlamarMesoneroMesa(state, datos) { 
      state.llamarMesoneroMesa = datos
    },
    setDocumentosIniciadosConteo(state, datos) { 
      state.documentosIniciados.conteo = datos
    },

    setinfoCargaDatos(state,datos){

      if (datos.tipo == 'sesion'){
        state.infoCargaDatos.sesion = datos.datos
      } 
      if (datos.tipo == 'version'){
        state.infoCargaDatos.version = datos.datos
      }
      if (datos.tipo == 'datosGenerales'){
        state.infoCargaDatos.datosGenerales = datos.datos
      }
      if (datos.tipo == 'puestoProductos'){
        state.infoCargaDatos.puestoProductos = datos.datos
      }

      if (
        state.infoCargaDatos.sesion.cargando == true ||
        state.infoCargaDatos.version.cargando == true ||
        state.infoCargaDatos.datosGenerales.cargando == true ||
        state.infoCargaDatos.puestoProductos.cargando == true 
      ) {
        state.infoCargaDatos.cargando = true
      }else{
        state.infoCargaDatos.cargando = false
      }
      if (
        state.infoCargaDatos.sesion.error == true ||
        state.infoCargaDatos.version.error == true ||
        state.infoCargaDatos.datosGenerales.error == true ||
        state.infoCargaDatos.puestoProductos.error == true 
      ) {
        state.infoCargaDatos.error = true
      }else{
        state.infoCargaDatos.error = false
      }

      if (
        state.infoCargaDatos.sesion.finalizado == true  &&
        state.infoCargaDatos.version.finalizado == true &&
        state.infoCargaDatos.datosGenerales.finalizado == true &&
        state.infoCargaDatos.puestoProductos.finalizado == true 
      ) {
        state.infoCargaDatos.finalizado = true
      }else{
        state.infoCargaDatos.finalizado = false
      }
      }
 
  },

  modules: {},
  plugins: [vuexLocal.plugin]
})

<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template v-slot:activator="{ on, attrs }">
      <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
        <v-avatar size="40px" v-bind="attrs" v-on="on">
          <v-img :src="`${config.api_imagenes}/empleado/img/${store.state.user.foto}`"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px">
            <v-img :src="`${config.api_imagenes}/empleado/img/${store.state.user.foto}`"></v-img>
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span class="text--primary font-weight-semibold mb-n1">
            {{ store.state.user.nombre }} {{ store.state.user.apellido }}
          </span>
          <small class="text--disabled text-capitalize">...</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- avatar -->
      <v-list-item link @click="val => $emit('AbrirAvatar')">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiFaceMan }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Avatar</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Profile -->
      <v-list-item link @click="val => $emit('AbrirPropina')" v-if="validarAcciones(acciones.DASHBOARD_COMANDA_VER_PROPINA)">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountCashOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Propinas</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link @click="val => $emit('AbrirClave')">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiKeyOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Cambiar Clave</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link @click="val => $emit('AbrirQr')"  v-if="validarAcciones(acciones.DASHBOARD_COMANDA_VER_QR)">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiQrcode }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Qr Wifi</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Settings -->
      <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content @click="val => $emit('AbrirConfig')">
          <v-list-item-title>Configuración</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiQrcode }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content @click="val => $emit('AbrirAutorizar')">
          <v-list-item-title>Autorizar</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item link @click="CerrarSesion()">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content v-on:click="CerrarSesion()">
          <v-list-item-title>Cerrar sesión</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiKey,
  mdiQrcode,
  mdiKeyOutline,
  mdiAccountCashOutline
} from '@mdi/js'
import store from '@/store'
import router from '@/router'
import config from '@/api/config'
import CambioClave from '@/views/sistema/administracion/archivo/empleado/CambioClave.vue'
import { ref } from '@vue/composition-api'
import { acciones } from '@/modulos'
import { socket, state as stateSocket } from '@/plugins/sockets'
export default {
  components: {
    CambioClave,
  },
  setup() {
    const mdiFaceMan =
      'M9,11.75A1.25,1.25 0 0,0 7.75,13A1.25,1.25 0 0,0 9,14.25A1.25,1.25 0 0,0 10.25,13A1.25,1.25 0 0,0 9,11.75M15,11.75A1.25,1.25 0 0,0 13.75,13A1.25,1.25 0 0,0 15,14.25A1.25,1.25 0 0,0 16.25,13A1.25,1.25 0 0,0 15,11.75M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,11.71 4,11.42 4.05,11.14C6.41,10.09 8.28,8.16 9.26,5.77C11.07,8.33 14.05,10 17.42,10C18.2,10 18.95,9.91 19.67,9.74C19.88,10.45 20,11.21 20,12C20,16.41 16.41,20 12,20Z'
    const CerrarSesion = () => {




     // socket.emit('join-delete', {  id_usuario: store.state.user.usuario })
      store.commit('setUserData', {
        userName: '',
        IsAutenticated: false,
        accion_modulo: '[ ]',
        age: 25,
        apellido: '',
        arbol: [],
        autenticate: true,
        caja: {},
        foto: '',
        id: 0,
        id_empleado: 0,
        modulo: '[]',
        nombre: '',
        puestos: [],
        token: '',
        usuario: '',
      })
      router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      })
    }
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiKey,
        mdiFaceMan,
        mdiQrcode,
        mdiKeyOutline,
        mdiAccountCashOutline
      },
      store,
      CerrarSesion,
      config,
      validarAcciones,
      acciones
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>

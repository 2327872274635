<template>
    
        <v-dialog v-model="dialog" scrollable max-width="800px">
            <v-card>
                <v-card-title>Selecciona tu avatar</v-card-title>
                <v-divider></v-divider>
                <v-card-text :style="'height:400px;'">
                    <v-item-group v-model="selected" mandatory>
                        <v-row>
                            <v-col v-for="(item, i) in items" :key="i" cols="12" md="4">
                                <v-item v-slot="{ active, toggle }">
                                    <v-avatar size="150">

                                        <v-img :src="`${config.api_imagenes}/empleado/img/${item}`"
                                            class="text-right pt-6 pl-2" @click="toggle">
                                            <v-btn icon>
                                                <v-icon>
                                                    {{ active ? icons.mdiCheckboxMarkedCircle :
                                                            icons.mdiCheckboxBlankCircleOutline
                                                    }}
                                                </v-icon>
                                            </v-btn>
                                        </v-img>
                                    </v-avatar>
                                </v-item>
                            </v-col>
                        </v-row>
                    </v-item-group>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>

                    <v-btn color="secondary" text @click="dialog = false">
                        Cerrar
                    </v-btn>
                    <v-btn color="primary" text @click="guardar()">
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
     
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import config from '@/api/config'
import store from '@/store'
import { mdiCheckboxMarkedCircle, mdiCheckboxBlankCircleOutline } from '@mdi/js'
import SeguridadService from '@/api/servicios/SeguridadService'
export default {
    setup() {
        const dialog = ref(false)
        const selected = ref(null)
        const items = ref([
            "Sin título-2_0024_Capa 4.jpg",
            "Sin título-2_0023_Capa 5.jpg",
            "Sin título-2_0022_Capa 6.jpg",
            "Sin título-2_0021_Capa 7.jpg",
            "Sin título-2_0020_Capa 8.jpg",
            "Sin título-2_0019_Capa 9.jpg",
            "Sin título-2_0018_Capa 10.jpg",
            "Sin título-2_0017_Capa 11.jpg",
            "Sin título-2_0016_Capa 12.jpg",
            "Sin título-2_0015_Capa 13.jpg",
            "Sin título-2_0014_Capa 14.jpg",
            "Sin título-2_0013_Capa 15.jpg",
            "Sin título-2_0012_Capa 16.jpg",
            "Sin título-2_0011_Capa 17.jpg",
            "Sin título-2_0010_Capa 18.jpg",
            "Sin título-2_0009_Capa 19.jpg",
            "Sin título-2_0008_Capa 20.jpg",
            "Sin título-2_0007_Capa 21.jpg",
            "Sin título-2_0006_Capa 22.jpg",
            "Sin título-2_0005_Capa 23.jpg",
            "Sin título-2_0004_Capa 24.jpg",
            "Sin título-2_0003_Capa 25.jpg",
            "Sin título-2_0002_Capa 26.jpg",
            "Sin título-2_0001_Capa 27.jpg",
            "Sin título-2_0000_Capa 28.jpg",
            "avatar__0011_Capa 1.jpg",
            "avatar__0010_Capa 2.jpg",
            "avatar__0009_Capa 3.jpg",
            "avatar__0008_Capa 4.jpg",
            "avatar__0007_Capa 5.jpg",
            "avatar__0006_Capa 6.jpg",
            "avatar__0005_Capa 7.jpg",
            "avatar__0004_Capa 8.jpg",
            "avatar__0003_Capa 9.jpg",
            "avatar__0002_Capa 10.jpg"

        ])
        const abrir = () => {
            dialog.value = true
        }

        onBeforeMount(() => {
            items.value.forEach((item, i) => {
                if (item == store.state.user.foto) {
                    selected.value = i
                }
            })
        })


        const guardar = () => {

            SeguridadService.modificarEmpleadoAvatar({
                img: items.value.find((item, i) => (i == selected.value)),
                id_empleado: store.state.user.id_empleado
            }).then(response => {
                if (response.data.mensaje == 'AVATAR MODIFICADO CON EXITO') {
                    store.commit('setAlert', {
                        message: response.data.mensaje,
                        type: 'success',
                    })
                    dialog.value = false
                } else {
                    store.commit('setAlert', {
                        message: response.data.mensaje,
                        type: 'warning',
                    })
                }
            })
                .catch(err => {
                    store.commit('setAlert', {
                        message: err,
                        type: 'error',
                        error:{
                        ...err, 
                        response: error?.response?.data
                    },
                         funcion:"guardar avatarSelect"
                    })
                    console.error(err);
                })
                .finally(() => {

                })
            const dat = { ...store.state.user, foto: items.value.find((item, i) => (i == selected.value)) }
            store.commit('setUserData', dat)
            console.log(store.state.user)

        }
        return {
            dialog,
            abrir,
            config,
            store,
            items,
            selected,
            icons: {
                mdiCheckboxMarkedCircle,
                mdiCheckboxBlankCircleOutline
            },
            guardar
        }
    },
}
</script>
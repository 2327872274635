<template>
  <v-snackbars style="z-index: 3000" :objects.sync="messages" :timeout="5000" top right>
    <template v-slot:default="{ message }">
      <h4 v-if="message.titulo" class="mb-2 white--text">
        <v-row>
          <v-col> <v-icon class="mr-2"> {{ message.icon }} </v-icon>{{ message.titulo }}  </v-col>
          <v-col  v-if="message.titulo == 'Error'" cols="auto"> <v-btn :disabled="!message.enviar_info" x-small outlined     @click="InformarError(message,$route.name)" color="white">informar Error</v-btn> </v-col>
        </v-row>
       
      </h4>
      {{ message.message }}
      
      
    
    </template>
    <template v-slot:action="{ close }">
      <v-btn icon @click="close()"
        ><v-icon> {{ icons.mdiClose }} </v-icon>
      </v-btn>
    </template>
  </v-snackbars>
</template>

<script>
import { watch, ref } from '@vue/composition-api'
import vSnackbars from 'v-snackbars'
import store from '@/store'
import { mdiClose, mdiAlertOutline, mdiAlertCircleOutline, mdiCheckCircleOutline,mdiInformationOutline } from '@mdi/js'
import MaestroService from '@/api/servicios/MaestroService'
import router from '@/router'
export default {
  watch: {
    '$store.state.alert': function (newVal, oldVal) {
      console.log('newVal', newVal)
      this.agregarMensaje(newVal)
   
    },
  },
  components: {
    'v-snackbars': vSnackbars,
  },
  setup() {
    const messages = ref([])
    const valor = ref(false)
    /* watch(this.$store.state.app, () => {
      if (valor.value == false) {
        valor.value = !!this.$store.state.app.alert.message
      }
    })*/
    watch(valor, () => {
      if (valor.value == false) {
        setTimeout(() => {
          store.commit('setAlert', {}, { root: true })
          
        }, 100)
      }
    })
    const closeAlert = () => {
      valor.value = false
    }

    const InformarError =(datos,link)=>{
      datos.enviar_info = false
      console.log('router',datos)
     // const alert = messages.value.find(ite=>ite.key == datos.key)

      const _datos = {
        ...datos,
        link,
        empresa: store.state.configuracionGeneral.nombre_empresa,
        usuario: store.state.user.usuario,
         
      }
      console.log("InformarError",_datos)
      MaestroService.EnviarCorreoInformeError(_datos)
      .then(response=>{
          console.log(response)
      })
      .catch(error=>{  console.log(response)})
      .finally(()=>{
        console.log("termino")
      })

     
    }
    let contador = 0
    const idDefaultProducto = () => {
      contador = contador + 1
      return contador
    }
    const agregarMensaje = newVal => {
      var icon = null
      var titulo = ''
      

      if (newVal.icon) {
        icon = newVal.icon
      } else {
        switch (newVal.type) {
          case 'success':
            icon = mdiCheckCircleOutline
            titulo = 'Exito'
            break
          case 'error':
            icon = mdiAlertCircleOutline
            titulo = 'Error'
            break
          case 'warning':
            icon = mdiAlertOutline
            titulo = 'Advertencia'
            break
          case 'info':
            icon = mdiInformationOutline
            titulo = 'Informacion'
            break
          default:
            break
        }
      }

      if (newVal.titulo) {
        titulo = newVal.titulo
      }
      const key = idDefaultProducto()

      const _dat = {
        ...newVal, 
        color: newVal.type,
        key: key,
        message: {
          funcion: newVal.funcion ,
          titulo: titulo,
          message: newVal.message,
          icon: icon,
          enviar_info : true ,
          error: newVal.error?.stack != undefined ? newVal.error?.stack  : newVal.error, 
        },
      }

      messages.value.push(_dat)

      if (valor == false) {
        valor = !!newVal.message
      }
    }

    return {
      closeAlert,
      valor,
      messages,
      agregarMensaje,
      InformarError,
      router,
      icons: {
        mdiClose,
        mdiAlertOutline,
        mdiAlertCircleOutline,
        mdiCheckCircleOutline,
        mdiInformationOutline
      },
    }
  },
}
</script>
<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card v-if="mensaje == 0">
      <v-card-title class="text-h5 grey text-center"> Solicitud de confirmacion </v-card-title>
      <v-card-text class="text-center">
        <v-row>
          <v-col cols="12">
            <p>
              El usuario {{ datos.empleado.nombre }} esta pidiendo autorizacion para realizar la accion
              {{ datos.accion }} <br />
              <small>{{ datos.idTran }}</small>
            </p>

            <small>Seleccione el numero que coinside</small>
          </v-col>

          <v-col cols="4" class="my-0 py-0" v-for="item in array" :key="item">
            <v-item>
              <v-card class="d-flex align-center primary" height="40" @click="confirmar(item)">
                <v-scroll-y-transition>
                  <div class="flex-grow-1 text-center">{{ item }}</div>
                </v-scroll-y-transition>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text class="text-center">
        <v-btn color="secondary" @click="cancelar()">({{ temporizador }}) Cancelar </v-btn>
      </v-card-text>
    </v-card>
    <v-card v-if="mensaje == 1">
      <v-card-text class="text-center">
        <h4>Autorizado</h4>
        <v-icon size="100" color="success">{{ icons.mdiCheckCircleOutline }}</v-icon>
      </v-card-text>
    </v-card>
    <v-card v-if="mensaje == 2">
      <v-card-text class="text-center">
        <h4>Error al seleccionar el codigo</h4>
        <v-icon size="100" color="error">{{ icons.mdiCancel }}</v-icon>
      </v-card-text>
    </v-card>
    <v-card v-if="mensaje == 3">
      <v-card-text class="text-center">
        <h4>Cancelado</h4>
        <v-icon size="100" color="secondary">{{ icons.mdiCancel }}</v-icon>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref } from '@vue/composition-api'
import { socket, state as stateSocket } from '@/plugins/sockets'
import store from '@/store'
import funciones from '@/funciones/funciones'
import { mdiCheckCircleOutline, mdiCancel } from '@mdi/js'
export default {
  setup(props, context) {
    const dialog = ref(false)
    let tipoValidacion = ref('CLAVE')
    //CLAVE , CONFIRMACION
    let funcion = null
    let datosFuncion = null
    const mensaje = ref(0)
    const array = ref([])
    const datos = ref({
      usuario: '',
      clave: 13,
      accion: 12,
      empleado: { id: 0, nombre: '' },
    })

    function compareRandom(a, b) {
      return Math.random() - 0.5
    }

    const confirmar = dato => {
      if (dato == datos.value.clave) {
        mensaje.value = 1

        socket.emit('autorizar', { ...datos.value, autorizacion: 1, id_empleado: store.state.user.id })

        setTimeout(function () {
          dialog.value = false
        }, 1000)
      } else {
        mensaje.value = 2

        socket.emit('autorizar', { ...datos.value, autorizacion: 2 })
        setTimeout(function () {
          dialog.value = false
        }, 1000)
      }
    }
    const temporizador = ref(0)
    var myInterval = null
    const abrir = _datos => {
      mensaje.value = 0
      dialog.value = true
      datos.value = _datos
      array.value = []
      array.value.push(_datos.clave)
      array.value.push(funciones.aleatorio(0, 100))
      array.value.push(funciones.aleatorio(0, 100))

      array.value.sort(compareRandom)

      clearInterval(myInterval)
      temporizador.value = 30
      myInterval = setInterval(function () {
        temporizador.value = temporizador.value - 1
        if (temporizador.value == 0) {
          mensaje.value = 3
          clearInterval(myInterval)
          setTimeout(function () {
            dialog.value = false
          }, 1000)
        }
      }, 1000)
    }

    const cancelar = () => {
      mensaje.value = 3

      socket.emit('autorizar', { ...datos.value, autorizacion: 0 })
      clearInterval(myInterval)
      setTimeout(function () {
        dialog.value = false
      }, 1000)
    }

    return {
      datos,
      dialog,
      abrir,
      tipoValidacion,
      array,
      confirmar,
      temporizador,
      mensaje,
      cancelar,
      icons: {
        mdiCheckCircleOutline,
        mdiCancel,
      },
    }
  },
}
</script>
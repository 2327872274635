export default {
   /* 
    //desarrollo
      api_imagenes: 'http://192.168.88.243/levelfood',
     api_master: 'http://192.168.88.243:5001/',
     api_impresora: 'http://localhost:5000/', 
  
   //produccion
        api_imagenes: 'http://192.168.0.30/level',
     api_master: 'http://192.168.0.30:5001/',
     api_impresora: 'http://localhost:5000/', 

     //vps
     api_imagenes: 'http://level.artysys.com/level',
     api_master: 'http://level.artysys.com:5001/',
     api_impresora: 'http://localhost:5000/',  
  */
   
    
     api_imagenes:  config.VUE_APP_URL_IMG  ,
     api_master: `${config.VUE_APP_URL_MASTER }/`,
     api_impresora: 'http://localhost:9563/', 
     api_estacionamiento: config.VUE_APP_URL_PARK, 
}

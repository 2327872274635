import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import preset from './default-preset/preset'
import preset2 from './default-preset/preset/index2'


Vue.use(Vuetify)
const presets = config.VUE_APP_URL_TEMA  == "1" ? preset : preset2
export default new Vuetify({
  preset: presets,
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
})

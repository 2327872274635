//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "../";
import router from '@/router'
import config from '../config';


class ProductosService {

  obtenerProductosPuestos(datos) {
    return http.get("inventario/producto/listarProductoPuesto", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
        }
      });
  }

  listarProductoBuscarPuesto(datos) {
    return http.get("inventario/producto/listarProductoBuscarPuesto", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
        }
      });
  }
  
  obtenerPuestos(datos) {
    return http.get("inventario/producto/listarPuesto", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
        }
      });
  }
  obtenerPuestosProductos(datos) {
    return http.get("inventario/producto/listarPuestoProductos", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
        }
      });
  }
  rptInventarioActual(params) {
    return http.get("inventario/producto/rptInventarioActual", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
        }
      });
  }
  rptInventarioActualIngAgrupado(params) {
    return http.get("inventario/producto/rptInventarioActualIngAgrupado", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
        }
      });
  }
  rptMovimientosPorDocumento(params) {
    return http.get("inventario/producto/rptMovimientosPorDocumento", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
        }
      });
  }
  reporteMovimientoProductoExistencia(params) {
    return http.get("inventario/reporteMovimientoProductoExistencia", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
        }
      });
  }


  
  rptMovimientoFechas(params) {
    return http.get("inventario/producto/rptMovimientoFechas", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
        }
      });
  }

  rptMovimientoProductoFechas(params) {
    return http.get("inventario/producto/rptMovimientoProductoFechas", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
        }
      });
  }
  rptTranslados(params) {
    return http.get("inventario/rptTranslados", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
        }
      });
  }
  
  consultarReferenciaExisteProducto(params) {
    return http.get("inventario/producto/consultarReferenciaExisteProducto", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
        }
      });
  }

  productoListarBusqueda(params) {
    return http.get("inventario/producto/productoListarBusqueda", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
        }
      });
  }

  productoExistenciaListarBusqueda(params) {
    return http.get("inventario/producto/productoExistenciaListarBusqueda", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
        }
      });
  }
  
  productoListarPaginado(params) {
    return http.get("inventario/producto/productoListarPaginado", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
        }
      });
  }

 
  productoActualizar(data) {
    return http.post("/inventario/producto/actualizar", data)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }

  productoModificarEestado(data) {
    return http.post("/inventario/producto/productoModificarEestado", data)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }

   
  subirImgProducto(data) {
    return http.post("/inventario/producto/upload", data, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    })
  } 

  subirImgProductoPHP(data) {
    return Axios.post(config.api_imagenes+"/img.php", data, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    })
  } 


}


export default new ProductosService();
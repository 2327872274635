import { reactive } from "@vue/composition-api";
import { io } from "socket.io-client";
import store from "@/store";

export const state = reactive({
  connected: false,
  fooEvents: [],
  barEvents: [],
  modificarComandaAll: 0,
  modificarComanda: 0
});

// "undefined" means the URL will be computed from the `window.location` object

const URL = (config.VUE_APP_URL_SOCKET || process.env.VUE_APP_URL_SOCKET)
export const socket = io(URL, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 99999
}
);
let errorCont = 0
socket.on("connect", () => {
  errorCont = 0
  state.connected = true;
  if (store.state.user.usuario) {

    socket.emit('join', { usuario: store.state.user.usuario })
  }
});
socket.on('modificar-comanda-all', (...args) => {
  state.modificarComandaAll = state.modificarComandaAll + 1
})
socket.on("disconnect", () => {
  state.connected = false;
});

socket.on('modificar-comanda', (...args) => {
  state.modificarComanda = state.modificarComanda + 1
});

socket.on('connect_error', err => {
  errorCont += 1
  if (errorCont == 5) {
    errorCont = 0
    store.commit('setAlert', {
      message: 'connect_error Socket',
      type: 'error',
      error: err,
      funcion: "connect_error Socket"
    })
  }
})
socket.on('connect_failed', err => {
  store.commit('setAlert', {
    message: 'connect_failed Socket',
    type: 'error',
    error: err,
    funcion: "connect_failed Socket"
  })
})




export const sistema = {
    administracion: 1,
    comanda: 2,
    puesto: 3
}

export const modulo = {
    dashboard_puesto: 3,
    dashboard_comanda: 5,
    dashboard_puesto_inventario_transformacion: 7,
    dashboard_puesto_inventario_recetario: 8,
    dashboard_puesto_inventario_fabricacion: 9,
    dashboard_puesto_inventario_ajuste: 10,
    dashboard_puesto_compra_factura: 11,
    dashboard_puesto_compra_nota_credito: 12,
    dashboard_puesto_compra_proveedor: 13,
    administracion_archivo_empleado: 18,
    dashboard_administracion: 23,
    administracion_archivo_puesto: 25,
    administracion_archivo_cliente: 55,
    administracion_pagos_factura: 30,
    administracion_pagos_proveedor: 31,
    administracion_pagos_concepto: 32,
    administracion_pagos_concepto: 37,
    administracion_ventas_reporte_general: 38,
    administracion_ventas_reporte_arqueo: 39,
    administracion_ventas_reporte_tipo_pago: 40,
    administracion_ventas_reporte_fechas: 41,
    administracion_ventas_reporte_mesonero: 42,
    dashboard_comanda_venta_reporte_arqueo: 44,
    dashboard_puesto_venta_reporte_arqueo: 49,
    dashboard_puesto_inventario_cambio_precio: 51,
    dashboard_puesto_inventario_reporte_fecha: 53,
    dashboard_puesto_inventario_reporte_movimiento: 54,
    administracion_ventas_cxc_dashboard: 57,
    administracion_ventas_cxc_procesar: 58,
    administracion_ventas_reporte_ventas_por_producto: 59
    , administracion_ventas_cxc_reportes: 60
    , administracion_ventas_cxc_reporte_pago_tipo_pago: 61
    , administracion_ventas_cxc_reporte_cuentas_global: 62
    , administracion_ventas_cxc_reporte_cuentas_detallado: 63
    , administracion_locatario_procesar: 65
    , administracion_locatario_reportes_cuenta_global: 68
    , administracion_propina_procesar: 70
    , administracion_propina_reposte_por_fecha: 72
    , administracion_banco_caja_banco: 75
    , administracion_banco_caja_caja: 76
    , administracion_banco_caja_cuenta_bancaria: 77
    , dashboard_comanda_mesas: 79
    , inventario_reporte_inventario_actual: 82
    , inventario_reporte_movimiento_agrupado: 83
    , inventario_reporte_movimiento_producto: 84
    , inventario_producto: 85
    , dashboard_puesto_inventario_reporte_inventario_actual: 86
    , administracion_ventas_reporte_propinas: 88
    , inventario_receta: 89
    , dashboard_puesto_inventario_solicitud: 90
    , administracion_ventas_reporte_comandasporproducto: 100
    , administracion_archivo_configuracion: 101,
    administracion_ventas_reporte_zetas: 102,
    administracion_banco_caja_tipo_cuenta: 107,
    administracion_banco_caja_punto_venta: 108,
    administracion_banco_caja_punto_pago: 109,
    administracion_ventas_reporte_libro_comandas: 110,
    banco_caja_reporte_punto_venta_transaccion: 116,
    contabilidad_configuracion_centro_costo: 120,
    contabilidad_configuracion_cuenta_contable: 119
}

export const acciones = {
    COMANDA_CREAR: 1,
    COMANDA_MODIFICAR: 2,
    COMANDA_VER: 3,
    COMANDA_IMPRIMIR: 5,
    DASHBOARD_COMANDA_IMPRIMIR: 6,
    DASHBOARD_COMANDA_CAMBIAR_ESTADO_COMANDA: 7,
    COMANDA_PROCESAR_PAGO_COMANDA: 8,
    COMANDA_MODIFICAR_ITEM_COMANDA: 11,
    COMANDA_MODIFICAR_PORCETAJES_COMISION: 12,
    SEGURIDAD_VER: 13,
    DASHBOARD_PUESTO_INVENTARIO_PRODUCTO_VER: 14,
    DASHBOARD_PUESTO_INVENTARIO_RECETARIO_VER: 15,
    DASHBOARD_PUESTO_INVENTARIO_FABRICACION_TRANSFORMACION_VER: 16,
    DASHBOARD_PUESTO_INVENTARIO_AJUSTE_VER: 17,
    DASHBOARD_PUESTO_COMPRA_FACTURA_VER: 18,
    DASHBOARD_PUESTO_COMPRA_NOTA_CREDITO_VER: 19,
    DASHBOARD_PUESTO_COMPRA_PROVEEDOR_VER: 20,
    INVENTARIO_VER: 21,
    COMPRA_VER: 22,
    REPORTES_VER: 23,
    ADMINISTRACION_VER: 24,
    ARCHIVOS_VER: 25,
    ADMINISTRACION_ARCHIVO_EMPLEADO_VER: 26,
    DASHBOARD_ADMINISTRACION_VER: 27,
    ADMINISTRACION_PAGOS_FACTURA_VER: 28,
    ADMINISTRACION_PAGOS_PROVEEDOR_VER: 29,
    ADMINISTRACION_PAGOS_CONCEPTO_VER: 30,
    ADMINISTRACION_PAGOS_CONCEPTO_VER: 31,
    ADMINISTRACION_VENTAS_REPORTE_GENERAL_VER: 32,
    ADMINISTRACION_VENTAS_REPORTE_TIPO_PAGO_VER: 33,
    ADMINISTRACION_VENTAS_REPORTE_FECHAS_VER: 34,
    ADMINISTRACION_VENTAS_REPORTE_MESONERO_VER: 35,
    ADMINISTRACION_VENTAS_REPORTE_LIBRO_COMANDA_VER: 152,

    ADMINISTRACION_ARCHIVO_PUESTO_VER: 36,
    ADMINISTRACION_VENTAS_REPORTE_ARQUEO_VER: 37,
    DASHBOARD_COMANDA_VENTA_REPORTE_ARQUEO_VER: 38,
    DASHBOARD_PUESTO_VER: 42,
    DASHBOARD_PUESTO_VENTA_REPORTE_ARQUEO_VER: 43,
    DASHBOARD_PUESTO_INVENTARIO_CAMBIO_PRECIO_VER: 44,
    DASHBOARD_PUESTO_INVENTARIO_REPORTE_FECHA_VER: 45,
    DASHBOARD_PUESTO_INVENTARIO_REPORTE_MOVIMIENTO_VER: 46,
    DASHBOARD_COMANDA_FILTRAR_COMANDA: 47,
    DASHBOARD_COMANDA_VER_COMANDA: 48,
    DASHBOARD_COMANDA_VER: 144,
    DASHBOARD_COMANDA_ENTRADA_SALIDA_CAJA: 145,

    DASHBOARD_COMANDA_ASIGNAR_CAJA: 146,
    DASHBOARD_COMANDA_IMPRIMIR_COMANDA: 49,
    DASHBOARD_COMANDA_AGREGAR_PRODUCTO: 50,
    DASHBOARD_COMANDA_BUSCAR_CLIENTE: 51,
    DASHBOARD_COMANDA_ASIGNAR_MENSONERO: 52,
    DASHBOARD_COMANDA_CREAR_COMANDA: 53,
    DASHBOARD_COMANDA_MODIFICAR_COMANDA: 54,
    DASHBOARD_COMANDA_PAGAR_COMANDA: 55,
    DASHBOARD_COMANDA_DESCUENTOS_COMANDA: 56,
    DASHBOARD_COMANDA_RECARGO_COMANDA: 57,
    DASHBOARD_COMANDA_CUENTAS_POR_COBRAR_CORTESIA: 58,
    DASHBOARD_COMANDA_FISCALIZAR_COMANDA: 59,
    DASHBOARD_COMANDA_REPORTE_X: 60,
    DASHBOARD_COMANDA_REPORTE_Z: 61,
    DASHBOARD_COMANDA_IMPRIMIR_INFO_FISCAL: 63,
    DASHBOARD_COMANDA_IMPRIMIR_ARQUEO_CAJA: 62,
    DASHBOARD_COMANDA_TRANSFERIR_COMANDA: 66,
    DASHBOARD_COMANDA_SEPARAR_COMANDA: 65,
    DASHBOARD_COMANDA_UNIR_COMANDA: 64,
    DASHBOARD_COMANDA_MOSTRAR_TODAS_COMANDA: 67,
    DASHBOARD_COMANDA_MODIFICA_PRECIOS: 134,
    DASHBOARD_COMANDA_VER_PROPINA: 136,
    DASHBOARD_COMANDA_VER_QR: 137,
    DASHBOARD_COMANDA_LISTAR_CLIENTES: 138,
    DASHBOARD_COMANDA_TIPO_PUESTO_COMIDA: 79,
    DASHBOARD_COMANDA_TIPO_PUESTO_TIKETS: 80,
    DASHBOARD_COMANDA_TIPO_PUESTO_CONTROL_INTERNO: 81,
    DASHBOARD_COMANDA_TIPO_PUESTO_ZONA_KIDS: 82,
    DASHBOARD_COMANDA_TIPO_PUESTO_SERVICIO: 83,
    DASHBOARD_COMANDA_TIPO_PUESTO_TIENDA_DE_ROPA: 84,
    DASHBOARD_COMANDA_VER_TOTALES: 106,
    DASHBOARD_PUESTOS_COMANDA_VER: 139,
    DASHBOARD_COMANDA_VER_PRECIOS: 140,
    DASHBOARD_ADMINISTRACION_VER_INDICADORES: 89,
    DASHBOARD_ADMINISTRACION_VER_TASA: 90,
    DASHBOARD_ADMINISTRACION_VER_CAJA: 91,
    DASHBOARD_ADMINISTRACION_VER_FECHAS: 92,
    DASHBOARD_ADMINISTRACION_VER_CIERRE_DIA: 93,
    DASHBOARD_ADMINISTRACION_VER_CIERRE_DIA: 93,
    DASHBOARD_ADMINISTRACION_VER_CIERRE_DIA: 93,
    DASHBOARD_ADMINISTRACION_ANULAR_COMANDA: 95,
    DASHBOARD_ADMINISTRACION_ANULAR_COMANDA_DETALLE: 96,
    DASHBOARD_ADMINISTRACION_COMANDA_MODIFICAR_PAGO: 97,

    INVENTARIO_COMPRA_MODIFICAR: 129,
    INVENTARIO_COMPRA_VALIDAR: 130,
    INVENTARIO_COMPRA_ANULAR: 131,
    banco_caja_reporte_punto_venta_transaccion_ver: 156,
    contabilidad_configuracion_centro_costo_ver: 159,
    contabilidad_configuracion_cuenta_contable_ver: 158,
    DASHBOARD_COMANDA_CERRAR_CUENTA_FORMA_PAGO: 164,
    TRASLADO_TIPO_GASTO :173

}


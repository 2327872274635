<template>
  <div class="text-center">
    <!--
        <v-tooltip bottom  >
            <template v-slot:activator="{ on, attrs }">
                <v-btn small @click="dialog = true" v-bind="attrs" v-on="on">
                    ${{totales.total_dia_actual.toFixed(2)}}
                </v-btn>
            </template>
            <span>Propina</span>
        </v-tooltip>
        -->
    <v-dialog v-model="dialog" width="900">
      <template> </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Propinas </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="dessertHeaders"
            :items="detalles"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            item-key="fecha"
            show-expand
            class="elevation-1"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-simple-table>
                  <thead>
                    <th class="text-left">Comanda</th>
                    <th class="text-left">Moneda</th>
                    <th class="text-left">Tipo</th>
                    <th class="text-right">Monto moneda</th>
                    <th class="text-right">Monto real</th>
                  </thead>
                  <tbody>
                    <tr v-for="(row, inx) in item.detalles" :key="inx">
                      <td class="text-left">{{ row.id_comanda }}</td>
                      <td class="text-left">{{ row.nombre_moneda }}</td>
                      <td class="text-left">{{ row.nombre_tipo_pago }}</td>
                      <td class="text-right">{{ row.simbolo }}{{ row.monto_moneda.toFixed(2) }}</td>
                      <td class="text-right">${{ row.monto_real.toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="text-center">
              <h3>Propina del dia</h3>
              <h1>{{ totales.total_dia_actual.toFixed(2) }}</h1>
            </v-col>
            <v-col cols="6">
              <v-simple-table>
                <tr>
                  <td>Total Propina</td>
                  <td>$ {{ totales.total.toFixed(2) }}</td>
                </tr>
                <tr>
                  <td>Total Pagos</td>
                  <td>$ {{ totales.pagado.toFixed(2) }}</td>
                </tr>
                <tr>
                  <td>Total Pendiente</td>
                  <td>$ {{ totales.pendiente.toFixed(2) }}</td>
                </tr>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { watch, ref, onBeforeMount } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import store from '@/store'

export default {
  props: {
    mensaje: Object,
  },
  setup(props) {
    const monto = ref(0.0)
    const dialog = ref(false)
    const detalles = ref([])
    const totales = ref({
      total_dia_actual: 0,
      total: 0,
      pagado: 0,
      pendiente: 0,
    })
    
    onBeforeMount(() => {
      ///consultarTotales()
    }) 
    const abrir = () => {
      dialog.value = true 
    }
    watch(dialog, () => {
      if (dialog.value == true) {
        consultarDetalles()
        consultarTotales()
      }
    })

    const consultarTotales = () => {
      MaestroService.PropinasTotales({ mesonero: store.state.user.id_empleado })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            totales.value = response.data.datos
          }
        })
        .catch(error => {
          console.error(error)
          store.commit('setAlert', {
                  message: error,
                  type: 'error',
                  error: {
                        ...error, 
                        response: error?.response?.data
                    },
                  funcion: 'consultarTotales',
                })
        })
        .finally(() => {})
    }

    const consultarDetalles = () => {
      MaestroService.PropinasDetalles({ mesonero: store.state.user.id_empleado })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            detalles.value = response.data.datos
          }
        })
        .catch(error => {
          console.error(error)
          store.commit('setAlert', {
                  message: error,
                  type: 'error',
                  error: {
                        ...error, 
                        response: error?.response?.data
                    },
                  funcion: 'consultarDetalles',
                })
        })
        .finally(() => {})
    }

    return {
      monto,
      dialog,
      totales,
      expanded: [],
      singleExpand: true,
      dessertHeaders: [
        { text: 'Fecha', value: 'fecha' },
        { text: 'Propina', value: 'total' },
        { text: 'Pagadas', value: 'pagado' },
        { text: 'Pendiente', value: 'pendiente' },
      ],
      detalles,
      abrir,
    }
  },
}
</script>
//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "../";
import router from '@/router'

class SeguridadService {
  obtenerEmpleadoTipos() {
    return http.get("seguridad/empladoTiposListar")
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  listarEmpleadoBusqueda(datos) {
    return http.get("/seguridad/empleadosListarBusqueda", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  obtenerEmpleados() {
    return http.get("seguridad/empladoListar")
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }



  arbolModulosListar(datos) {
    return http.get("seguridad/arbolModulosListar", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  reportesItemTree(datos) {
    return http.get("seguridad/reportesItemTree", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }



  obtenerRol(datos) {
    return http.get("seguridad/rolListar", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  subirImgEmpleado(data) {
    return http.post("/empleado/upload", data, {
      headers: { "Content-Type": "multipart/form-data" }
    })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      })
  }

  modificarEmpleadoAvatar(data) {
    return http.post("/seguridad/modificarEmpleadoAvatar", data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      })
  }
  modificarEmpleado(data) {
    return http.post("/seguridad/modificarEmpleado", data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      })
  }

  modificarEmpleadoEstado(data) {
    return http.post("/seguridad/modificarEmpleadoEstado", data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      })
  }

  cambiarClave(data) {
    return http.post("/seguridad/cambiarClave", data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      })
  }
  validarCredencialesAccionModulo(data) {
    return http.post("/seguridad/validarCredencialesAccionModulo", data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      })
  }
  validarUsuarioAccionModulo(data) {
    return http.post("/seguridad/validarUsuarioAccionModulo", data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      })
  }

  listarMesonerosLogin() {
    return http.get(`/seguridad/listarMesonerosLogin`)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  listarMesonerosComanda() {
    return http.get(`/seguridad/listarMesonerosComanda`)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  create(data) {
    return http.post("/tutorials", data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  update(id, data) {
    return http.put(`/tutorials/${id}`, data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  delete(id) {
    return http.delete(`/tutorials/${id}`)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  deleteAll() {
    return http.delete(`/tutorials`)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  findByTitle(title) {
    return http.get(`/tutorials?title=${title}`)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
}


export default new SeguridadService();
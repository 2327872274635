<template>
  <v-sheet class="overflow-hidden">
    <v-navigation-drawer
      width="300"
      :value="isDrawerOpen"
      absolute
      temporary
      dark
      floating
      hide-overlay
      touchless
      fixed
      @input="val => $emit('update:is-drawer-open', val)"
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-img :src="`${config.api_imagenes}/empleado/img/${store.state.user.foto}`"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title> {{ store.state.user.nombre }} {{ store.state.user.apellido }} </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <HoraActual></HoraActual>
      <v-container>
        <v-row>
          <v-subheader> Tickera </v-subheader>
          <v-col cols="12">
            <v-select
              :items="impresoras"
              :value="store.state.tikera"
              @change="selectTikera"
              item-value="id"
              item-text="nombre"
              label="Tickera Default"
              dense
              outlined
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-if="validarAcciones(acciones.DASHBOARD_PUESTOS_COMANDA_VER)"
              type="numeric"
              label="Copias Ticket Puesto"
              outlined
              dense
              hide-details
              v-model="copiasTickera"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row v-if="validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA)">
          <v-subheader> Impresora fiscal </v-subheader>
          <v-col cols="12">
            <v-select
              outlined
              dense
              v-model="tipo_fiscal"
              :items="['LOCAL', 'REMOTO']"
              label="Tipo de impresora"
              hide-details
              @change="modificarImpresoraTipo"
            ></v-select>
          </v-col>
          <v-col cols="12" v-if="tipo_fiscal == 'LOCAL'">
            <v-row>
              <v-col class="d-flex" cols="6">
                <v-text-field
                  label="modeloImpresora"
                  outlined
                  dense
                  hide-details
                  v-model="impresoraFiscal.modeloImpresora"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="6">
                <v-text-field label="bAudios" outlined dense hide-details v-model="impresoraFiscal.bAudios">
                </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="6">
                <v-text-field label="paridad" outlined dense hide-details v-model="impresoraFiscal.paridad">
                </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="6">
                <v-text-field label="puerto" outlined dense hide-details v-model="impresoraFiscal.puerto">
                </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="6">
                <v-text-field label="datos" outlined dense hide-details v-model="impresoraFiscal.datos"> </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="6">
                <v-text-field label="stopBits" outlined dense hide-details v-model="impresoraFiscal.stopBits">
                </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="6">
                <v-text-field label="handshake" outlined dense hide-details v-model="impresoraFiscal.handshake">
                </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="6">
                <v-text-field
                  label="maxTiempoEspera"
                  outlined
                  dense
                  hide-details
                  v-model="impresoraFiscal.maxTiempoEspera"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="6">
                <v-text-field
                  label="tiempoAntesLectura"
                  outlined
                  dense
                  hide-details
                  v-model="impresoraFiscal.tiempoAntesLectura"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="tipo_fiscal == 'REMOTO'">
            <v-select  item-value="id"  hide-details
            item-text="nombre" @change="modificarImpresoraSelect" outlined dense v-model="impresoraFiscalSelect"  :items="impresorasFiscal" label="Impresora Fiscal"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-subheader> Tema </v-subheader>
          <v-col class="d-flex" cols="12">
            <v-switch v-model="$vuetify.theme.dark" :label="`Modo oscuro`"></v-switch>
          </v-col>
          <div class="text-center">
            <v-subheader> Version: {{ VERSION }} </v-subheader>
          </div>
        </v-row>
      </v-container>
    </v-navigation-drawer>
  </v-sheet>
</template>
<script>
import { watch, ref, onBeforeMount, onBeforeUpdate } from '@vue/composition-api'
import store from '@/store'
import config from '@/api/config'
import MaestroService from '@/api/servicios/MaestroService.js'
import { acciones } from '@/modulos'

import HoraActual from '@/components/HoraActual.vue'
export default {
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  components: {
    HoraActual,
  },
  watch: {
    impresoraFiscal: {
      handler: function (newVal) {
        this.modificarImpresora()
      },
      deep: true,
    },
    copiasTickera: {
      handler: function (newVal) {
        this.$store.commit('SetCopiaTicket', this.copiasTickera)
      },
      deep: true,
    },
  },
  setup() {
    const VERSION = process.env.VUE_APP_VERSION
    const copiasTickera = ref('')
    const tipo_fiscal = ref('LOCAL')
    const impresoraFiscal = ref({
      modeloImpresora: 1,
      puerto: 99,
      bAudios: 9600,
      paridad: 2,
      datos: 8,
      stopBits: 1,
      handshake: 0,
      maxTiempoEspera: 5000,
      tiempoAntesLectura: 100,
    })
    const modificarImpresora = () => {
      store.commit('setImpresoraFiscal', {
        modeloImpresora: parseInt(impresoraFiscal.value.modeloImpresora),
        puerto: parseInt(impresoraFiscal.value.puerto),
        bAudios: parseInt(impresoraFiscal.value.bAudios),
        paridad: parseInt(impresoraFiscal.value.paridad),
        datos: parseInt(impresoraFiscal.value.datos),
        stopBits: parseInt(impresoraFiscal.value.stopBits),
        handshake: parseInt(impresoraFiscal.value.handshake),
        maxTiempoEspera: parseInt(impresoraFiscal.value.maxTiempoEspera),
        tiempoAntesLectura: parseInt(impresoraFiscal.value.tiempoAntesLectura),
      })
    }

    const modificarImpresoraSelect = () => {
      store.commit('setImpresoraFiscalSelect',  impresorasFiscal.value.find(ite=>ite.id == impresoraFiscalSelect.value)   )
    }

    const modificarImpresoraTipo = () => {
      store.commit('setImpresoraFiscalTipo', tipo_fiscal.value )
    }

    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const impresoraFiscalSelect = ref(-1);
    onBeforeMount(() => {
      impresoraFiscal.value = { ...store.state.impresoraFiscal }
      impresoraFiscalSelect.value = store.state.impresoraFiscalSelect?.id
      tipo_fiscal.value = store.state.impresoraFiscalTipo



      copiasTickera.value = store.state.copiasTickera
      consultarImpresoras()
      if (validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA)) {
        consultarImpresorasFiscal()
      }
    })

    const consultarImpresoras = () => {
      MaestroService.obtenerGrupoImpresora()
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            impresoras.value = []

            if (validarAcciones(acciones.DASHBOARD_ADMINISTRACION_VER)) {
              impresoras.value = response.data.datos
            } else {
              if (validarAcciones(acciones.DASHBOARD_COMANDA_IMPRIMIR)) {
                impresoras.value = impresoras.value.concat(response.data.datos.filter(its => its.ind_mesoneros == true))
              }
              if (validarAcciones(acciones.DASHBOARD_PUESTOS_COMANDA_VER)) {
                impresoras.value = impresoras.value.concat(response.data.datos.filter(its => its.ind_puestos == true))
              }
            }
          }
        })
        .catch(erro => {
          console.error(erro)
        })
    }
    const impresorasFiscal = ref([])
    const consultarImpresorasFiscal = () => {
      MaestroService.obtenerImpresoraFiscal()
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            impresorasFiscal.value = response.data.datos 
          }
        })
        .catch(erro => {
          console.error(erro)
        })
    }

    const selectTikera = item => {
      let dato = {}
      impresoras.value.forEach(i => {
        if (i.id.toString() == item.toString()) {
          dato = i
        }
      })

      store.commit('seTikera', dato)
    }
    const impresoras = ref([
      {
        id: 1,
        nombre: 'comanda 1',
      },
      {
        id: 2,
        nombre: 'comanda 2',
      },
      {
        id: 3,
        nombre: 'comanda 3',
      },
      {
        id: 4,
        nombre: 'caja 1',
      },
      {
        id: 5,
        nombre: 'caja 2',
      },
    ])
    const drawer = ref(null)
    const items = ref([
      { title: 'Home', icon: 'mdi-view-dashboard' },
      { title: 'About', icon: 'mdi-forum' },
    ])

    return {
      drawer,
      items,
      config,
      store,
      impresoras,
      selectTikera,
      impresoraFiscal,
      impresoraFiscalSelect,
      validarAcciones,
      acciones,
      modificarImpresora,
      VERSION,
      copiasTickera,
      tipo_fiscal,
      impresorasFiscal,
      modificarImpresoraSelect,
modificarImpresoraTipo
    }
  },
}
</script>
<template>
  <span>
    <small :class="pingResult > 0 ? 'success--text' : 'error--text'">{{ pingResult }}ms</small>
    <v-btn icon color="info" @click="abrir(true)">
      <v-icon v-if="state.connected" color="success">{{ icons.mdiLanConnect }}</v-icon>
      <v-icon v-else color="error">{{ icons.mdiLanDisconnect }}</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" scrollable max-width="600px">
      <v-card>
        <v-card-title>Conexion con el servidor</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-list-item v-for="(item, i) in listaResultados" :key="i">
            <v-list-item-content>
              <v-list-item-title :class="colorValor(item.valor)"> {{ item.mensaje }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="mb-1">
            <v-col class="text-center">
              Minimo <br />
              <small>{{ minimo }} ms</small>
            </v-col>
            <v-col class="text-center">
              Maximo <br />
              <small>{{ maximo }} ms</small>
            </v-col>

            <v-col class="text-center">
              Media <br />
              <small>{{ promedio }} ms</small>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="mt-1 mb-1">
            <v-col class="text-center">
              Enviados <br />
              <small>{{ paquetesEnviados }}</small>
            </v-col>
            <v-col class="text-center success--text">
              Recibidos <br />
              <small>{{ paquetesRecibidos }}</small>
            </v-col>
            <v-col class="text-center error--text">
              Perdidos <br />
              <small>{{ paquetesPerdidos }}</small>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn :color="inicio ? 'error' : 'success'" @click="Iniciar()"> {{ inicio ? 'Detener' : 'Iniciar' }} </v-btn>
          <v-btn color="secondary" @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
  <script>
import Ping from 'ping.js'
import { ref, onBeforeMount } from '@vue/composition-api'
import { mdiLanPending, mdiLanConnect, mdiLanDisconnect } from '@mdi/js'
import goTo from 'vuetify/lib/services/goto'
import store from '@/store'

import { socket, state } from '@/plugins/sockets' 
export default {
  setup() {
    const dialog = ref(false)
    const listaResultados = ref([])
    const inicio = ref(false)
    const servidor = (config.VUE_APP_URL_IMG || process.env.VUE_APP_URL_IMG) + '/assets/images'
    const promedio = ref(0)
    const maximo = ref(0)
    const minimo = ref(0)
    const paquetesPerdidos = ref(0)
    const paquetesEnviados = ref(0)
    const paquetesRecibidos = ref(0)
    onBeforeMount(()=>{
      ping2()
    })
    const Promedio = () => {
      if (listaResultados.value.length > 0) {
        var suma = 0
        var max = 0
        var min = 9999
        listaResultados.value.forEach(item => {
          if (max < item.valor) {
            max = item.valor
          }

          if (min > item.valor) {
            min = item.valor
          }
          if (item.valor > -1) {
            suma += Number(item.valor)
          }
        })
        if (suma > 0) {
          promedio.value = parseInt(suma / listaResultados.value.filter(item => item.valor > -1).length)
        }
        maximo.value = max
        minimo.value = min
        paquetesPerdidos.value = listaResultados.value.filter(item => item.valor == -1).length
        paquetesEnviados.value = listaResultados.value.length
        paquetesRecibidos.value = paquetesEnviados.value - paquetesPerdidos.value
      }
    }
    const colorValor = valor => {
      if (Number(valor) >= 0 && Number(valor) <= 100) {
        return 'success--text'
      } else if (Number(valor) > 100 && Number(valor) <= 200) {
        return 'warning--text'
      } else {
        return 'error--text'
      }
    }
    const abrir = valor => {
      dialog.value = valor
      inicio.value = false
      listaResultados.value = []
      promedio.value = 0
      maximo.value = 0
      minimo.value = 0
      paquetesPerdidos.value = 0
      paquetesEnviados.value = 0
      paquetesRecibidos.value = 0
    }
    const Iniciar = () => {
      inicio.value = !inicio.value
      if (inicio.value) {
        ping()
      }
    }
    const pingResult = ref(0)
    const ping2 = async () => {
      const start = Date.now()
      try {
        await socket.emit('ping', () => {
          const duration = Date.now() - start
          pingResult.value = duration

          setTimeout(() => {
          
              ping2()
             
          }, 5000)
        })
      } catch (error) {
        duration.value = -1
      }
    }
    const ping = async () => {
      const start = Date.now()
      try {
        await socket.emit('ping', () => {
          const duration = Date.now() - start
          listaResultados.value.push({ mensaje: 'ping ' + duration + 'ms', valor: duration })
          Promedio()
          setTimeout(() => {
            if (inicio.value && dialog.value) {
              ping()
            }
          }, 1000)
        })
      } catch (error) {
        listaResultados.value.push({ mensaje: 'Error', valor: -1 })
      }
    }

    return {
      dialogm1: '',
      dialog,
      listaResultados,
      Iniciar,
      icons: {
        mdiLanPending,
        mdiLanConnect,
        mdiLanDisconnect,
      },
      inicio,
      abrir,
      colorValor,
      promedio,
      maximo,
      minimo,
      paquetesPerdidos,
      paquetesEnviados,
      paquetesRecibidos,
      store,
      state,
      pingResult,
    }
  },
}
</script>
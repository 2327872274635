<template>
  <small>{{ FuncionesGenerales.formatoFecha(store.state.horaActual, 2) }}</small>
</template> 
<script>
import store from '@/store'
import { watch, ref, onBeforeMount } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import FuncionesGenerales from '@/funciones/funciones'
import moment from 'moment' 
export default {
  setup() {
    let counterConsultas = ref(0)
    onBeforeMount(() => {
      cargar(0)
    })
    watch(counterConsultas, () => {
      setTimeout(() => {
        cargar(10)
      }, 10000)
    })

    const cargar = cant => {
      if (cant == 0) {
        MaestroService.HoraActual()
          .then(response => {
            console.log(response.data.now)
            store.commit('setHoraActual', response.data.now, { root: true })
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            counterConsultas.value += 1
          })
      } else {
        store.commit('setSegundoHoraActual', cant, { root: true })
         counterConsultas.value += 1
      }
    }
    return {
      store,
      FuncionesGenerales,
    }
  },
}
</script>
//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class VersionService {



  /**
   * 
  chatListarAll
  chatListar
  chatEnviarMensaje
   */

  chatListarAll(datos) {
    return http.get("chat/chatListarAll",  { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  
  chatListar(datos) {
    return http.get("chat/chatListar",  { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  chatEnviarMensaje(datos) {
    return http.post(`/chat/chatEnviarMensaje`, datos)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        } else {
          throw error
        }
      });
  }

}





export default new VersionService();
//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "../";
import router from '@/router'

class MaestroService {

  consultaSql(datos) {
    return http.get("maestro/consultaSql", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  

  configuracionListar(datos) {
    return http.get("maestro/configuracionListar", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  
  configuracionActualizar(datos) {
    return http.post(`/maestro/configuracionActualizar`, datos)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  obtenerGrupoImpresora() {
    return http.get("maestro/listarGrupoTikera")
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  obtenerImpresoraFiscal() {
    return http.get("maestro/listarImpresoraFiscal")
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  imprimirGrupoTikera(datos) {
    return http.get("maestro/imprimirGrupoTikera", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  imprimirComandaPuesto(datos) {
    return http.get("maestro/imprimirComandaPuesto", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  
  

  imprimirArqueoCajaDiferencias(datos) {
    return http.get("maestro/imprimirArqueoCajaDiferencias", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  imprimirGrupoTikeraPagos(datos) {
    return http.get("maestro/imprimirGrupoPagadaTikera", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  imprimirArqueoCajaTikera(datos) {
    return http.get("maestro/imprimirArqueoCajaTikera", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  imprimirArqueoCajaTikeraDetallado(datos) {
    return http.get("maestro/imprimirArqueoCajaTikeraDetallado", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  
  ArrayDiasCerrados() {
    return http.get("maestro/ArrayDiasCerrados")
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  ReporteCXCClienteDetallado(datos) {
    return http.get("maestro/ReporteCXCClienteDetallado", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  ReporteCXCClienteGrupo(datos) {
    return http.get("maestro/ReporteCXCClienteGrupo", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  reporteCXCGlobalGrupo(datos) {
    return http.get("maestro/ReporteCXCGlobalGrupo", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  reporteCXCGlobalTodos(datos) {
    return http.get("maestro/reporteCXCGlobalTodos", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  reporteDatosVentasGeneral(datos) {
    return http.get("maestro/ReporteDatosVentasGeneral", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }


  
  reporteDatosVentasGeneralPuesto(datos) {
    return http.get("maestro/ReporteDatosVentasGeneralPuesto", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  RptDashCierreDia(datos) {
    return http.get("maestro/RptDashCierreDia", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  
  rptTasasDias(datos) {
    return http.get("maestro/rptTasasDias", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  

  RptDashCierrePuestoMes(datos) {
    return http.get("maestro/RptDashCierrePuestoMes", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  RptDashCierrePuesto(datos) {
    return http.get("maestro/RptDashCierrePuesto", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  RptDashProductoMasVendidos(datos) {
    return http.get("maestro/RptDashProductoMasVendidos", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  RptDashVentasMesoneros(datos) {
    return http.get("maestro/RptDashVentasMesoneros", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  RptDashPuestoMasVende(datos) {
    return http.get("maestro/RptDashPuestoMasVende", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  RptDashPuestoTipoMasVende(datos) {
    return http.get("maestro/RptDashPuestoTipoMasVende", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  RptDashCierreDiaMensual(datos) {
    return http.get("maestro/RptDashCierreDiaMensual", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  RptDashCierreDiaDiasSemana(datos) {
    return http.get("maestro/RptDashCierreDiaDiasSemana", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }



  RptArqueoCajaDiaAgrupado(datos) {
    return http.get("maestro/RptArqueoCajaDiaAgrupado", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  RptArqueoCxCAgrupado(datos) {
    return http.get("maestro/RptArqueoCxCAgrupado", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }


  
  RptDetalladoComandasDia(datos) {
    return http.get("maestro/RptDetalladoComandasDia", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  RptArqueoCajaDiaAgrupadoCaja(datos) {
    return http.get("maestro/RptArqueoCajaDiaAgrupadoCaja", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  RptArqueoCajaDiaAgrupadoDiferenciasGrupo(datos) {
    return http.get("maestro/RptArqueoCajaDiaAgrupadoDiferenciasGrupo", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  RptArqueoCajaDiaAgrupadoPropina(datos) {
    return http.get("maestro/RptArqueoCajaDiaAgrupadoPropina", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  RptVentasPorPuestoFormaPagoTotalizados(datos) {
    return http.get("maestro/RptVentasPorPuestoFormaPagoTotalizados", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  tutorialesListar(datos) {
    return http.get("maestro/tutorialesListar", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  ListarTablas(datos) {
    return http.get("maestro/ListarTablas", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  


  etiquetasListar(datos) {
    return http.get("maestro/etiquetasListar", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  etiquetasActualizar(datos) {
    return http.post(`/maestro/etiquetasActualizar`, datos)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  
  RptVentasPorPuestoFormaPagoDetallado(datos) {
    return http.get("maestro/RptVentasPorPuestoFormaPagoDetallado", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  RptServiciosPorDia(datos) {
    return http.get("maestro/RptServiciosPorDia", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  
  

  rptVetasMesoneroProducto(datos) {
    return http.get("maestro/rptVetasMesoneroProducto", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  
  rptVetasMesoneroProductoComision(datos) {
    return http.get("maestro/rptVetasMesoneroProductoComision", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  RptCxcPorComanda(datos) {
    return http.get("maestro/RptCxcPorComanda", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  RptDashVentaMesas(datos) {
    return http.get("maestro/RptDashVentaMesas", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  ReporteCXCFormasPagosDetallado(datos) {
    return http.get("maestro/ReporteCXCFormasPagosDetallado", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  ReporteCXPPuesto(datos) {
    return http.get("maestro/ReporteCXPPuesto", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  reporteDatosFechas(datos) {
    return http.get("maestro/ReporteDatosFechas")
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  reporteCantidadComandasPuesto(datos) {
    return http.get("maestro/ReporteCantidadComandasPuesto", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  reporteCantidadComandas(datos) {
    return http.get("maestro/ReporteCantidadComandas", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  ReporteVentasXProductoFechas(datos) {
    return http.get("maestro/ReporteVentasXProductoFechas", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  reporteDatosUltimasComprasProductos(datos) {
    return http.get("maestro/reporteDatosUltimasComprasProductos", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
 

  logProductoPaginado(datos) {
    return http.get("maestro/logProductoPaginado", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }


  reporteValorizadoGeneral(datos) {
    return http.get("maestro/reporteValorizadoGeneral", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  
  reporteDatosComprasProductosAgrupado(datos) {
    return http.get("maestro/reporteDatosComprasProductosAgrupado", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  reportedatosultimasventasproductos(datos) {
    return http.get("maestro/reportedatosultimasventasproductos", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  reportedatosventasproductosagrupado(datos) {
    return http.get("maestro/reportedatosventasproductosagrupado", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }



  reporteDatosArqueoCaja(datos) {
    return http.get("maestro/ReporteDatosArqueoCaja", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  listarMonedas(datos) {
    return http.get("maestro/ListarMonedas", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  listarHistorialTasasMonedas(datos) {
    return http.get("maestro/ListarHistorialTasasMonedas", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  
  ReporteRelacionCajaPuestoGrupoNew(datos) {
    return http.get("maestro/ReporteRelacionCajaPuestoGrupoNew", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  reporteRelacionCajaPuestoGrupo(datos) {
    return http.get("maestro/ReporteRelacionCajaPuestoGrupo", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  reporteVentasPorPuesto(datos) {
    return http.get("maestro/reporteVentasPorPuesto", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  reporteMovimientoProductoPorPuesto(datos) {
    return http.get("maestro/reporteMovimientoProductoPorPuesto", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  
  cajasActivasDia(datos) {
    return http.get("maestro/cajasActivasDia", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  rptVentaCajaProducto(datos) {
    return http.get("maestro/rptVentaCajaProducto", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }



  rptProductoAnulados(datos) {
    return http.get("maestro/rptProductoAnulados", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  reporteTotalizadoCaja(datos) {
    return http.get("maestro/reporteTotalizadoCaja", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }


  DatosConfiguracion() {
    return http.get("maestro/DatosConfiguracion");
  }
  HoraActual() {
    return http.get("maestro/horaActual")
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/login");
        }
      });
  }
  cambiarTasaCambio(datos) {
    return http.post(`/maestro/CambiarTasaCambio`, datos)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }


  
  reportesView(datos) {
    return http.get("maestro/reportesView", { params: datos })
      .catch((error) => {

        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
        console.log(error)
      });
  }

  ejecutarReporte(datos) {
    return http.get("maestro/ejecutarReporte", { params: datos })
      .catch((error) => {

        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
        console.log(error)
      });
  }

  
  
  reportesActualizar(datos) { 
    return http.post(`maestro/reportesActualizar`, datos)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    }); 
  }

  reportesListar(datos) {
    return http.get("maestro/reportesListar", { params: datos })
      .catch((error) => {

        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
        console.log(error)
      });
  }
  reporteListarComandas(datos) {
    return http.get("maestro/ReporteListarComandas", { params: datos })
      .catch((error) => {

        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
        console.log(error)
      });
  }
  ReporteLibroComandas(datos) {
    return http.get("maestro/ReporteLibroComandas", { params: datos })
      .catch((error) => {

        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
        console.log(error)
      });
  }
  versionSistemaActual(datos) {
    return http.get("maestro/versionSistemaActual", { params: datos })
      .catch((error) => {

        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
        console.log(error)
      });
  }


  
  GruposMesasListar(datos) {
    return http.get("maestro/GruposMesas", { params: datos })
      .catch((error) => {

        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
        console.log(error)
      });
  }

  CajerosListar(datos) {
    return http.get("maestro/CajerosListar", { params: datos })
      .catch((error) => {

        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
        console.log(error)
      });
  }
  CajasListar(datos) {
    return http.get("maestro/CajasListar", { params: datos })
      .catch((error) => {

        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
        console.log(error)
      });
  }
  CerrarDiaActual(datos) {
    return http.get("maestro/CerrarDiaActual", { params: datos })
      .catch((error) => {

        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
        console.log(error)
      });
  }

  puestoPorFormasPagos(datos) {
    return http.get("maestro/puestoPorFormasPagos", { params: datos })
      .catch((error) => {

        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
        console.log(error)
      });
  }
  cajaVincularEmpleado(datos) {
    return http.get("maestro/cajaVincularEmpleado", { params: datos })
      .catch((error) => {

        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
        console.log(error)
      });
  }

  impuestoTasaListar(datos) {
    return http.get("maestro/impuestoTasaListar", { params: datos })
      .catch((error) => {

        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
        console.log(error)
      });
  }

  listarEstados(datos) {
    return http.get("maestro/listarEstados", { params: datos })
      .catch((error) => {

        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
        console.log(error)
      });
  }


  cajaDesvincularEmpleado(datos) {
    return http.get("maestro/cajaDesvincularEmpleado", { params: datos })
      .catch((error) => {

        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
        console.log(error)
      });
  }
  

  obtenerFormasPagoPropinas(datos) {
    return http.get("maestro/obtenerFormasPagoPropinas", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  PropinasTotales(datos) {
    return http.get("maestro/PropinasTotales", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  PropinasDetalles(datos) {
    return http.get("maestro/PropinasDetalles", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  EnviarCorreoInformeError(datos) {



    return http.post(`maestro/EnviarCorreoInformeError`, datos)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });

     
  }
  

}





export default new MaestroService();
<template>
    <div>
        <template v-if="tipoBtn == 2">
            <v-btn color="primary" dark @click="dialog = true">
                Cambiar clave
            </v-btn>
        </template>

        <v-list-item link v-if="tipoBtn == 1">
            <v-list-item-icon class="me-2">
                <v-icon size="22">
                    {{ icons.mdiKey }}
                </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>Cambiar Clave</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-btn class="ms-3" icon small v-if="tipoBtn == 3" @click="dialog = true">
            <v-icon>
                {{ icons.mdiKeyOutline }}
            </v-icon>
        </v-btn>

        <v-dialog v-model="dialog" persistent max-width="300px">

            <v-card>
                <v-card-title>
                    <span class="text-h5">Cambio de clave</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-container>
                            <v-row>

                                <v-col cols="12" class="pt-0">
                                    <v-text-field :disabled="cargando" v-model="datos.clave_actual"
                                        :rules="ruleClaveActual" type="password" label="Clave Aterior" required dense
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-text-field :disabled="cargando" v-model="datos.clave_nueva"
                                        :rules="ruleClaveNueva" type="password" label="Clave nueva" dense outlined>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-text-field :disabled="cargando" v-model="datos.clave_confirmacion"
                                        :rules="ruleClaveConfirmacion" type="password" label="Confimar clave" dense
                                        outlined>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" :loading="cargando" text @click="CambiarClave()">
                        Cambiar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { mdiKey, mdiKeyOutline } from '@mdi/js'
import store from '@/store'
import SeguridadService from '@/api/servicios/SeguridadService'
export default {
    props: {
        tipoBtn: String
    },
    setup(props, context) {

        const dialog = ref(false)
        const valid = ref(true);
        const form = ref(null);
        const datos = ref({
            clave_actual: "",
            clave_nueva: "",
            clave_confirmacion: ""
        })
        const cargando = ref(false)
        const ruleClaveActual = [
            v => !!v || "Clave actual es requerida",
        ];
        const ruleClaveNueva = [
            v => !!v || "CLave nueva es requerida",
            v => (v && v.length >= 3) || "Debe contener 4 digitos o mas",
        ];
        const ruleClaveConfirmacion = [
            v => !!v || "Confimacion de clave es requerida",
            v => (v && v == datos.value.clave_nueva) || "la confirmacion debe coincidir con la clave nueva",
        ];
        const abrir =()=>{
            dialog.value = true 
        }

        watch(dialog, () => {
            if (dialog.value == true) {
                datos.value = {
                    clave_actual: "",
                    clave_nueva: "",
                    clave_confirmacion: ""
                }
                form.value?.reset()
            }
        })
        const validarForm = () => {
            let val = form.value?.validate()

            return val
        }

        const CambiarClave = () => {
            if (validarForm()) {
                cargando.value = true
                SeguridadService.cambiarClave({ ...datos.value, id_empleado: store.state.user.id_empleado })
                    .then(response => {
                        if (response.data.mensaje == 'CLAVE MODIFICADA CON EXITO') {
                            store.commit('setAlert', {
                                message: response.data.mensaje,
                                type: 'success',
                            })
                            dialog.value = false
                        } else {
                            store.commit('setAlert', {
                                message: response.data.mensaje,
                                type: 'info',
                            })
                        }
                    })
                    .catch(error => {
                        store.commit('setAlert', {
                            message: 'ups hubo un error: ' + error,
                            type: 'error',
                            
                            error: {
                        ...error, 
                        response: error?.response?.data
                    },
                            funcion: 'CambiarClave',
                        })
                    })
                    .finally(() => {
                        cargando.value = false
                    })
            } else {
                store.commit('setAlert', {
                    message: 'Debe rellenar todos los campos con datos validos',
                    type: 'warning',
                    
                })
            }

        }


        return {
            dialog,
            icons: {
                mdiKey,
                mdiKeyOutline
            },
            valid,
            ruleClaveActual,
            ruleClaveNueva,
            ruleClaveConfirmacion,
            datos,
            CambiarClave,
            form,
            cargando,
            abrir
        }
    },
}
</script>
<template>
  <v-card>
    <v-card-title class="px-0 py-0">
      <v-toolbar color="primary" light extended style="z-index: 100">
        <v-toolbar-title class="white--text">
          <span v-if="visual == 1"> Mis chats </span>
          <span v-if="visual == 2"> {{ chat.contacto }} </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="cerrarModal()" v-if="dialogChat">
          <v-icon color="white">{{ icons.mdiExitToApp }}</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-btn fab color="cyan accent-2" bottom left absolute @click="dialog2 = true" v-if="visual == 1">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
          </v-btn>
          <v-btn color="secondary" outlined x-small v-if="visual == 2" class="ma-2 white--text">
            Eliminar Chat
            <v-icon color="error" small>{{ icons.mdiDelete }}</v-icon>
          </v-btn>
        </template>
      </v-toolbar>
    </v-card-title>

  
    <v-card-text style="max-height: 500px" ref="cardChatRef">
      <div  v-if="visual == 2">
        <v-row justify="space-around d-flex flex-column">
        <div v-if="cargandoChat" class="text-center">cargando chat...</div>
        <div v-else>
          <v-btn block class="mt-4" @click="scrollTopChat()">Cargar mas chats</v-btn>
          <v-card v-for="(message, index) in chat.chat.slice().reverse()" :key="index" flat>
            <v-list-item :key="index" v-if="message.id_empleado_envia != store.state.user.id_empleado" class="">
              <v-list-item-avatar class="align-self-start mr-2">
                <v-avatar size="40">
                  <v-img :src="`${config.api_imagenes}/empleado/img/${chat.img_contacto}`"></v-img>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content class="received-message">
                <v-card color="success" class="flex-none">
                  <v-card-text class="white--text pa-2 d-flex flex-column">
                    <span class="text-caption">{{ chat.contacto }} </span>
                    <span class="align-self-start text-subtitle-1">{{ message.mensaje }}</span>
                    <span class="text-caption font-italic align-self-end">{{
                      funciones.formatoFecha(message.fecha_envia, 3)
                    }}</span>
                  </v-card-text>
                </v-card>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else :key="index">
              <v-list-item-content class="sent-message justify-end">
                <v-card color="primary" class="flex-none">
                  <v-card-text class="white--text pa-2 d-flex flex-column">
                    <span class="text-subtitle-1 chat-message">
                      {{ message.mensaje }}
                    </span>
                    <span class="text-caption font-italic align-self-start">
                      <v-icon v-if="message.fecha_leido" x-small color="info">{{ icons.mdiCheckAll }}</v-icon>
                      <v-icon v-else-if="message.fecha_recibe" x-small>{{ icons.mdiCheckAll }}</v-icon>
                      <v-icon v-else x-small>{{ icons.mdiCheck }}</v-icon>
                      {{ funciones.formatoFecha(message.fecha_envia, 3) }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-list-item-content>
              <v-list-item-avatar class="align-self-start ml-2">
                <v-img :src="`${config.api_imagenes}/empleado/img/${store.state.user.foto}`"></v-img>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
        </div>
      </v-row>
      </div>
      <div v-if="visual == 1">
        <v-list two-line subheader>
        <v-subheader inset> chats </v-subheader>

        <v-list-item v-for="item in items" :key="item.id" link @click="cargarChats(item)">
          <v-list-item-avatar>
            <v-badge bordered bottom color="success" dot offset-x="10" offset-y="10">
              <v-avatar size="40px">
                <v-img :src="`${config.api_imagenes}/empleado/img/${item.img_contacto}`"></v-img>
              </v-avatar>
            </v-badge>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ item.contacto }}</v-list-item-title>
            <v-list-item-subtitle><small v-if="conectado(item.id_contacto)">En Linea</small> </v-list-item-subtitle>

            <v-list-item-subtitle>{{ item.ultimo_chat.mensaje }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-chip class="ma-2 success" small v-if="item.sin_leer > 0">{{ item.sin_leer }}</v-chip>
            <small>{{ funciones.formatoFecha(item.ultimo_chat.fecha_envia, 3) }}</small>
          </v-list-item-action>
        </v-list-item>

        <v-divider inset></v-divider>
      </v-list>
      </div>
     
    </v-card-text>
    <v-card-actions v-if="visual == 2">
      <v-row>
        <v-col class="my-0">
          <v-textarea
            hide-details
            :loading="enviando"
            v-model="mensaje"
            outlined
            dense
            placeholder="Mensaje"
            rows="2"
          ></v-textarea>
        </v-col>
        <v-col cols="2" class="text-center">
          <v-btn :disabled="mensaje == ''" :loading="enviando" large class="primary" icon @click="enviarMensaje()"
            ><v-icon color="white">{{ icons.mdiExitToApp }}</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
    <v-dialog v-model="dialog2" max-width="400px">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Seleccione un empleado </v-card-title>
        <v-card-text>
          <BuscarEmpleado @Getdatos="getEmpleado" :filtro="{estado:5}" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small append @click="dialog2 = false"> Cerrar </v-btn>
          <v-btn color="primary" small append @click="iniciarChat()"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { ref, onBeforeMount, onBeforeUpdate, watch } from '@vue/composition-api'
import { mdiExitToApp, mdiPlus, mdiCheckAll, mdiCheck, mdiDelete } from '@mdi/js'
import config from '@/api/config'
import ChatService from '@/api/servicios/ChatService'
import store from '@/store'
import funciones from '@/funciones/funciones'
import moment from 'moment'

import { socket, state as stateSocket } from '@/plugins/sockets'
import BuscarEmpleado from '@/views/sistema/administracion/archivo/empleado/components/BuscarEmpleado.vue'
export default {
  props: {
    dialogChat: {
      type: Boolean,
      default: false,
    },
  },
  components: { BuscarEmpleado },
  watch: {
    visual: function () {
      if (this.visual == 2) {
        this.scrollTopChat()
      }
    },
  },

  setup(props, context) {
    const consultarUsuarios = () => {
      socket.emit('listar-conectados', {})
    }

    socket.on('listar-conectados', args => {
      empleados_conectados.value = args
    })
    const empleados_conectados = ref()
    const dialog2 = ref(false)
    const mensaje = ref('')
    const cardChatsRef = ref()
    const offsetTop = ref(0)
    const dialog = ref(false)
    const items = ref()
    const chat = ref({})
    const visual = ref(1)
    const iniciarChat = () => {}
    const getEmpleado = item => {
      console.log(item)
      chat.value = {
        id_contacto: item.id,
        contacto: `${item.nombre} ${item.apellido}`,
        img_contacto: item.img,
        chat: [],
      }

      visual.value = 2
      dialog2.value = false
    }

    const cerrarModal = async () => {
      if (visual.value == 1) {
        context.emit('cerrarModal', true)
      } else {
        visual.value = 1
        chat.value = {}
        await delay(100)
          scrollTopChat(0)
      }
    }
    function delay(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    }

    const recibirChat = async _dato => {
      console.log(_dato)
      _dato = _dato[0]
      if (_dato.id_contacto == chat.value.id_contacto) {
        chat.value.chat.unshift({
          fecha_envia: _dato.fecha_envia,
          fecha_leido: _dato.fecha_leido,
          fecha_recibe: _dato.fecha_recibe,
          id: _dato.id,
          id_empleado_envia: _dato.id_empleado_envia,
          id_empleado_recibe: _dato.id_empleado_recibe,
          ind_borrado_envia: _dato.ind_borrado_envia,
          ind_borrado_recibe: _dato.ind_borrado_recibe,
          mensaje: _dato.mensaje,
        })

        await delay(100)
        scrollTopChat()
      } else {
        const x = items.value.find(it => it.id_contacto == _dato.id_contacto)
        if (x) {
          x.ultimo_chat = {
            fecha_envia: _dato.fecha_envia,
            fecha_leido: _dato.fecha_leido,
            fecha_recibe: _dato.fecha_recibe,
            id: _dato.id,
            id_empleado_envia: _dato.id_empleado_envia,
            id_empleado_recibe: _dato.id_empleado_recibe,
            ind_borrado_envia: _dato.ind_borrado_envia,
            ind_borrado_recibe: _dato.ind_borrado_recibe,
            mensaje: _dato.mensaje,
          }
          x.sin_leer += 1
        } else {
          items.value.unshift({
            sin_leer: 1,
            img_contacto: _dato.img_contacto,
            contacto: _dato.contacto,
            id_contacto: _dato.id_contacto,
            ultimo_chat: {
              fecha_envia: _dato.fecha_envia,
              fecha_leido: _dato.fecha_leido,
              fecha_recibe: _dato.fecha_recibe,
              id: _dato.id,
              id_empleado_envia: _dato.id_empleado_envia,
              id_empleado_recibe: _dato.id_empleado_recibe,
              ind_borrado_envia: _dato.ind_borrado_envia,
              ind_borrado_recibe: _dato.ind_borrado_recibe,
              mensaje: _dato.mensaje,
            },
          })
        }
      }
    }

    const enviando = ref(false)
    const errorMensaje = ref('')
    const enviarMensaje = () => {
      enviando.value = true
      errorMensaje.value = ''
      const datos = {
        id_empleado_envia: store.state.user.id_empleado,
        id_empleado_recibe: chat.value.id_contacto,
        mensaje: mensaje.value,
      }
      ChatService.chatEnviarMensaje(datos)
        .then(async response => {
          if (response.data.mensaje == 'ENVIADO CON EXITO') {
            chat.value.chat.unshift(response.data.datos)
            const x = items.value.find(it => it.id_contacto == chat.value.id_contacto)
            if (x) {
              x.ultimo_chat = response.data.datos
            } else {
              cargarChat()
            }

            mensaje.value = ''
            await delay(100)
            scrollTopChat()
          } else {
            errorMensaje.value = response.data.mensaje
          }
        })
        .catch(error => {
          errorMensaje.value = 'Hubo un error al enviar el mensaje'
          console.log(error)
        })
        .finally(() => {
          enviando.value = false
        })
    }

    const cargarChats = async _dato => {
      chat.value = {
        ..._dato,
        chat: [],
      }

      visual.value = 2
      ChatService.chatListarAll({ id_empleado: store.state.user.id_empleado, id_contacto: _dato.id_contacto })
        .then(response => {
          chat.value = {
            ...chat.value,
            chat: response.data,
          }
          _dato.sin_leer = 0
          console.log(chat.value)
        })
        .catch(error => {})
        .finally(async () => {
          await delay(100)
          scrollTopChat()
        })
    }

    const cargarChat = async () => {
      ChatService.chatListar({ id_empleado: store.state.user.id_empleado })
        .then(response => {
          console.log(response.data)
          items.value = response.data
        })
        .catch(error => {})
        .finally(() => {})
    }

    const cardChatRef = ref(null)
    const scrollTopChats = () => {
      cardChatsRef.value.scrollTop = cardChatsRef.value.lastElementChild.offsetTop
    }

    const scrollTopChat = (top) => {
      if(!top){
        top =  cardChatRef.value.scrollHeight 
      }
       

      cardChatRef.value.scrollTop = top
    }

    const conectado = id => {
      const x = empleados_conectados.value.find(it => it.id_empleado == id)
      if (x) {
        return true
      } else {
        return false
      }
    }

    onBeforeMount(() => {
      cargarChat()
      consultarUsuarios()
    })

    return {
      dialog,
      items,
      cerrarModal,
      chat,
      visual,
      offsetTop,
      cardChatsRef,
      icons: {
        mdiExitToApp,
        mdiPlus,
        mdiCheckAll,
        mdiCheck,
        mdiDelete,
      },
      scrollTopChats,
      scrollTopChat,
      cardChatRef,
      cargarChats,
      config,
      cargarChat,
      store,
      funciones,
      mensaje,
      enviarMensaje,
      dialog2,
      getEmpleado,
      iniciarChat,
      recibirChat,
      empleados_conectados,
      consultarUsuarios,
      conectado,
      enviando,
      errorMensaje,
    }
  },
}
</script>

<style>
</style>
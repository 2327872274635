<template>
  <div>
    <v-autocomplete
      :prepend-icon="icon ? icons.mdiAccountTie : null"
      v-model="model"
      :items="items"
      :filter="customFilter"
      :loading="isLoading"
      :search-input.sync="search"
      clearable
      hide-details
      hide-selected
      item-text="nombre"
      item-value="id"
      :label="label" 
      outlined
      dense
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title> No hay empleados </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:selection="{ item }">
        <span>{{ item.nombre }} {{ item.apellido }}</span>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-avatar color="primary" size="30" class="my-2 py-2 font-weight-light white--text">
          <small>{{ item.nombre.charAt(0) }}{{ item.apellido.charAt(0) }}</small>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.nombre }} {{ item.apellido }}
            <br />
            <small> {{ item.ci }}</small>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <small> {{ item.usuario_json.usuario }}</small>
        </v-list-item-action>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { mdiAccountTie } from '@mdi/js'
import SeguridadService from '@/api/servicios/SeguridadService'
export default {
  props: {
    icon: Boolean,
    label: {
      type: String,
      default: 'Buscar Empleado',
    },
    filtro: null
  },

  setup(props, context) {
    const dialog = ref(false)
    const isLoading = ref(false)
    const items = ref([])
    const model = ref(null)
    const search = ref(null)
    const tab = ref(null)

    const customFilter = (item, queryText, itemText) => {
      const filtro1 = item.ci.toString()
      const filtro2 = item.nombre
      const filtro3 = item.apellido
      const filtro4 = item.usuario_json.usuario
      const searchText = queryText.toUpperCase()

      return (
        filtro1?.indexOf(searchText) > -1 ||
        filtro2?.indexOf(searchText) > -1 ||
        filtro3?.indexOf(searchText) > -1 ||
        filtro4?.indexOf(searchText) > -1
      )
    }

    watch(model, () => {
      context.emit(
        'Getdatos',
        items.value.find(item => item.id == model.value),
      )
    })

    watch(search, () => {
      Buscar()
    })

    const Buscar = () => {
      // Items have already been loaded
      /*if (items.value.length > 0)
                return*/

      if (isLoading.value == true) return

      isLoading.value = true
      setTimeout(() => {
        // Lazily load input items

        SeguridadService.listarEmpleadoBusqueda({ busqueda: search.value, ...props.filtro })
          .then(response => {
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              items.value = response.data.datos
            } else {
              items.value = []
            }
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (isLoading.value = false))
      }, 1000)
    }

    return {
      icons: {
        mdiAccountTie,
      },
      dialog,
      isLoading,
      items,
      model,
      search,
      tab,
      customFilter,
    }
  },
}
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import { sistema, modulo } from '@/modulos/index'
import store from '@/store'

Vue.use(VueRouter)

const ValidarPermiso = (to, from, next) => {
    if (to.meta.requiresAuth) {
        if (store.state.user.IsAutenticated === true) {
            if (to.meta.modulo !== undefined) {
                if (to.meta.modulo == 0 || to.meta.modulo == undefined) {
                    next()
                } else {
                    const permisos = store.state.user?.modulo
                    if (permisos?.includes(to.meta.modulo)) {
                        next() // Take you to /something
                    } else {
                        // If params.blah is blank or in your case, does not have permission, redirect back to the home page
                        next({ name: 'index' })
                    }
                }
            } else {
                // If params.blah is blank or in your case, does not have permission, redirect back to the home page
                next()
            }
        } else {
            // If params.blah is blank or in your case, does not have permission, redirect back to the home page
            next({ name: 'pages-login' })
        }
    } else {
        next() // Take you to /something
    }

}
const IrHome = (to, from, next) => {
    let permisos = []
    if (store.state.user != null && store.state.user.modulo != null && store.state.user.modulo != undefined) {
        permisos = store.state.user?.modulo?.replace('[', '').replace(']', '').split(",")
    }

    if (permisos.find(item => item == modulo.dashboard_administracion) != undefined) {

        next({ name: 'dashboard-administracion' })

    } else {
        if (permisos.find(item => item == modulo.dashboard_comanda) != undefined) {

            next({ name: 'dashboard-comanda' })
        } else {
            if (permisos.find(item => item == modulo.dashboard_puesto) != undefined) {

                next({ name: 'dashboard-puesto' })
            } else {

                next({ name: 'inicio' })
            }
        }
    }
}

const general = [
    {
        path: '/',
        name: "index",
        beforeEnter: IrHome,
        //redirect: '/dashboard/comanda',
    },
]

const comanda = [
    {
        path: '/inicio',
        name: 'inicio',
        component: () => import('@/views/sistema/inicio/Inicio.vue'),
        meta: {
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/facturacion',
        name: 'facturacion',
        component: () => import('@/views/sistema/facturacion/Facturacion.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.dashboard_comanda,

        },
        beforeEnter: ValidarPermiso,


    },{
        path: '/Presupuesto/:vista?',
        name: 'presupuesto',
        component: () => import('@/views/sistema/presupuesto/Presupuesto.vue'),
        meta: {
            requiresAuth: true,
            full:true
            //modulo: modulo.dashboard_comanda,

        },
        beforeEnter: ValidarPermiso,


    },

    
    {
        path: '/dashboard/comanda',
        name: 'dashboard-comanda',
        component: () => import('@/views/sistema/comanda/dashboard/Dashboard.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.dashboard_comanda,

        },
        beforeEnter: ValidarPermiso,


    },
    {
        path: '/comanda/venta/reporte/arqueo',
        name: 'dashboard-comanda-venta-reporte-arqueo',
        component: () => import('@/views/sistema/comanda/venta/reporte/arqueo/Arqueo.vue'),
        meta: {
            requiresAuth: true,
           //modulo: modulo.dashboard_comanda_venta_reporte_arqueo
        },
        beforeEnter: ValidarPermiso
    },


    {
        path: '/comanda/mesas',
        name: 'dashboard-comanda-mesas',
        component: () => import('@/views/sistema/comanda/mesas/Mesas.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.dashboard_comanda_venta_reporte_arqueo
        },
        beforeEnter: ValidarPermiso
    }


]

const inventario = [
    {
        path: '/inventario/producto',
        name: 'inventario-producto',
        component: () => import('@/views/sistema/inventario/producto/Producto.vue'),
        meta: {
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/inventario/combo',
        name: 'inventario-combo',
        component: () => import('@/views/sistema/inventario/combo/Combo.vue'),
        meta: {
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/inventario/receta',
        name: 'inventario-receta',
        component: () => import('@/views/sistema/inventario/receta/Receta.vue'),
        meta: {
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/inventario/reporte/inventarioactual',
        name: 'inventario-reporte-inventario-actual',
        component: () => import('@/views/sistema/inventario/reporte/inventario-actual/InventarioActual.vue'),
        meta: {
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/inventario/reporte/movimientoagrupado',
        name: 'inventario-reporte-movimiento-agrupado',
        component: () => import('@/views/sistema/inventario/reporte/movimiento-agrupado/MovimientoAgrupado.vue'),
        meta: {
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/inventario/reporte/movimientoproducto',
        name: 'inventario-reporte-movimiento-producto',
        component: () => import('@/views/sistema/inventario/reporte/movimiento-producto/MovimientoProducto.vue'),
        meta: {
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    }, {
        path: '/inventario/reporte/transformaciongeneral',
        name: 'inventario-reporte-transformacion-general',
        component: () => import('@/views/sistema/inventario/reporte/transformacion-general/TransformacionGeneral.vue'),
        meta: {
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    }, {
        path: '/inventario/reporte/transformaciondetallado',
        name: 'inventario-reporte-transformacion-detallado',
        component: () => import('@/views/sistema/inventario/reporte/transformacion-detalle/TransformacionDetalle.vue'),
        meta: {
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    }
    
    , {
        path: '/inventario/reporte/valorizadoinventario',
        name: 'inventario-reporte-valorizado-inventario',
        component: () => import('@/views/sistema/inventario/reporte/valorizado-inventario/ValorizadoInventario.vue'),
        meta: {
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    }
    , {
        path: '/inventario/reporte/fabricaciongeneral',
        name: 'inventario-reporte-fabricacion-general',
        component: () => import('@/views/sistema/inventario/reporte/fabricacion-general/FabricacionGeneral.vue'),
        meta: {
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    }, {
        path: '/inventario/reporte/fabricaciondetallado',
        name: 'inventario-reporte-fabricacion-detallado',
        component: () => import('@/views/sistema/inventario/reporte/fabricacion-detalle/FabricacionDetalle.vue'),
        meta: {
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/inventario/reporte/transladoGeneral',
        name: 'inventario-reporte-translado-general',
        component: () => import('@/views/sistema/inventario/reporte/translado-general/TransladoGeneral.vue'),
        meta: {
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/inventario/reporte/transladoDetalle',
        name: 'inventario-reporte-translado-detalle',
        component: () => import('@/views/sistema/inventario/reporte/translado-detalle/TransladoDetalle.vue'),
        meta: {
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    }
]


const puesto = [

    {
        path: '/dashboard/puesto',
        name: 'dashboard-puesto',
        component: () => import('@/views/sistema/puesto/dashboard/Dashboard.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.dashboard_puesto
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/puesto/venta/reporte/arqueo',
        name: 'dashboard-puesto-venta-reporte-arqueo',
        component: () => import('@/views/sistema/puesto/venta/reporte/arqueo/Arqueo.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.dashboard_puesto_inventario_ajuste
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/puesto/compra/factura/:vista?',
        name: 'dashboard-puesto-compra-factura',
        component: () => import('@/views/sistema/puesto/compra/factura/Factura.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.dashboard_puesto_compra_factura,
            full:true
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/puesto/compra/ordencompra',
        name: 'dashboard-puesto-compra-orden-compra',
        component: () => import('@/views/sistema/puesto/compra/orden-compra/OrdenCompra.vue'),
        meta: {
            requiresAuth: true,
           // modulo: modulo.dashboard_puesto_compra_factura
        },
        beforeEnter: ValidarPermiso
    },
    
    {
        path: '/puesto/compra/reporte/historialcompras',
        name: 'dashboard-puesto-compra-reporte-historial-compras',
        component: () => import('@/views/sistema/puesto/compra/reporte/historial-compras/HistorialCompras.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.dashboard_puesto_compra_factura
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/puesto/compra/reporte/cxpGlobal',
        name: 'puesto-compra-reporte-cxc-global',
        component: () => import('@/views/sistema/puesto/compra/reporte/cuentas-por-pagar-global/CuentasPorPagarGlobal.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.dashboard_puesto_compra_factura
        },
        beforeEnter: ValidarPermiso
    }, {
        path: '/puesto/compra/reporte/cxpDetallado',
        name: 'puesto-compra-reporte-cxc-detallado',
        component: () => import('@/views/sistema/puesto/compra/reporte/cuenta-por-pagar-detalles/CuentaPorPagarDetallado.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.dashboard_puesto_compra_factura
        },
        beforeEnter: ValidarPermiso
    }, {
        path: '/puesto/compra/reporte/comprasporcuentacontable',
        name: 'puesto-compra-reporte-comprasporcuentacontable',
        component: () => import('@/views/sistema/puesto/compra/reporte/compras-por-cuenta-contable/ComprasPorCuentaContable.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.dashboard_puesto_compra_factura
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/puesto/compra/nota_credito/:vista?',
        name: 'dashboard-puesto-compra-nota-credito',
        component: () => import('@/views/sistema/puesto/compra/nota-credito/NotaCredito.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.dashboard_puesto_compra_nota_credito,
            full:true
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/puesto/compra/proveedor',
        name: 'dashboard-puesto-compra-proveedor',
        component: () => import('@/views/sistema/puesto/compra/proveedor/Proveedor.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.dashboard_puesto_compra_proveedor
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/puesto/inventario/ajuste',
        name: 'dashboard-puesto-inventario-ajuste',
        component: () => import('@/views/sistema/puesto/inventario/ajuste/Ajuste.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.dashboard_puesto_inventario_ajuste
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/inventario/reporte/ajustegeneral',
        name: 'inventario-reporte-ajuste-general',
        component: () => import('@/views/sistema/inventario/reporte/ajuste-general/AjusteGeneral.vue'),
        meta: {
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/inventario/reporte/ajustedetalle',
        name: 'inventario-reporte-ajuste-detalle',
        component: () => import('@/views/sistema/inventario/reporte/ajuste-detalle/AjusteDetalle.vue'),
        meta: {
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/puesto/inventario/cambio_precio',
        name: 'dashboard-puesto-inventario-cambio-precio',
        component: () => import('@/views/sistema/puesto/inventario/cambio-precio/CambioPrecio.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.dashboard_puesto_inventario_cambio_precio
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/puesto/inventario/transformacion',
        name: 'dashboard-puesto-inventario-transformacion',
        component: () => import('@/views/sistema/puesto/inventario/transformacion/Transformacion.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.dashboard_puesto_inventario_transformacion
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/puesto/inventario/translado/:id_solicitud?',
        name: 'dashboard-puesto-inventario-translado',
        component: () => import('@/views/sistema/puesto/inventario/translado/Translado.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.dashboard_puesto_inventario_translado
        },
        beforeEnter: ValidarPermiso
    }, {
        path: '/puesto/inventario/solicitud',
        name: 'dashboard-puesto-inventario-solicitud',
        component: () => import('@/views/sistema/puesto/inventario/solicitud/Solicitud.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.dashboard_puesto_inventario_solicitud
        },
        beforeEnter: ValidarPermiso
    }, {
        path: '/puesto/inventario/conteo',
        name: 'puesto-inventario-conteo',
        component: () => import('@/views/sistema/puesto/inventario/conteo/Conteo.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.dashboard_puesto_inventario_solicitud
        },
        beforeEnter: ValidarPermiso
    }, {
        path: '/puesto/inventario/merma',
        name: 'dashboard-puesto-inventario-merma',
        component: () => import('@/views/sistema/puesto/inventario/merma/Merma.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.dashboard_puesto_inventario_solicitud
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/puesto/inventario/recetario',
        name: 'dashboard-puesto-inventario-recetario',
        component: () => import('@/views/sistema/puesto/inventario/recetario/Recetario.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.dashboard_puesto_inventario_recetario
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/puesto/inventario/fabricacion',
        name: 'dashboard-puesto-inventario-fabricacion',
        component: () => import('@/views/sistema/puesto/inventario/fabricacion/Fabricacion.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.dashboard_puesto_inventario_fabricacion
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/puesto/inventario/reporte/inventarioactual',
        name: 'dashboard-puesto-inventario-reporte-inventario-actual',
        component: () => import('@/views/sistema/puesto/inventario/reporte/inventario-actual/InventarioActual.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.dashboard_puesto_inventario_reporte_fecha
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/puesto/inventario/reporte/inventarioactualIngAgrupado',
        name: 'dashboard-puesto-inventario-reporte-inventario-actual-ing-agrupado',
        component: () => import('@/views/sistema/puesto/inventario/reporte/inventario-actual-ing-agrupado/InventarioActualIngAgrupado.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.dashboard_puesto_inventario_reporte_fecha
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/puesto/inventario/reporte/MovimientoPorDocumentos',
        name: 'dashboard-puesto-inventario-reporte-movimiento-por-documentos',
        component: () => import('@/views/sistema/puesto/inventario/reporte/movimientos-por-documentos/MovimientoPorDocumentos.vue'),
        meta: {
            requiresAuth: true,
            
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/puesto/inventario/reporte/movimientoproducto',
        name: 'dashboard-puesto-inventario-movimento-producto',
        component: () => import('@/views/sistema/puesto/inventario/reporte/movimiento-producto/MovimientoProducto.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.dashboard_puesto_inventario_reporte_fecha
        },
        beforeEnter: ValidarPermiso
    }, {
        path: '/puesto/inventario/reporte/movimientoproductoinventario',
        name: 'dashboard-puesto-inventario-movimento-producto-inventario',
        component: () => import('@/views/sistema/inventario/reporte/movimiento-producto-existencia/MovimientoProductoExistencia.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.dashboard_puesto_inventario_reporte_fecha
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/puesto/inventario/reporte/movimiento',
        name: 'dashboard-puesto-inventario-reporte-movimiento',
        component: () => import('@/views/sistema/puesto/inventario/reporte/movimiento/Movimiento.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.dashboard_puesto_inventario_reporte_movimiento
        },
        beforeEnter: ValidarPermiso
    }
]
const administracion = [

    {
        path: '/dashboard/administracion',
        name: 'dashboard-administracion',
        component: () => import('@/views/sistema/administracion/dashboard/Dashboard.vue'),
        meta: {
            label: "Administracion",
            icon: "Administracion",
            requiresAuth: true,
            modulo: modulo.dashboard_administracion
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/administracion/archivo/empleado',
        name: 'administracion-archivo-empleado',
        component: () => import('@/views/sistema/administracion/archivo/empleado/Empleado.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_archivo_empleado
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/archivo/rol',
        name: 'administracion-archivo-rol',
        component: () => import('@/views/sistema/administracion/archivo/rol/Rol.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_archivo_empleado
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/archivo/puesto',
        name: 'administracion-archivo-puesto',
        component: () => import('@/views/sistema/administracion/archivo/puesto/Puesto.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_archivo_puesto
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/archivo/cliente',
        name: 'administracion-archivo-cliente',
        component: () => import('@/views/sistema/administracion/archivo/cliente/Cliente.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_archivo_cliente
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/archivo/reportes/:vista?',
        name: 'administracion-archivo-reportes',
        component: () => import('@/views/sistema/administracion/archivo/reportes/Reportes.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.administracion_archivo_cliente,
            full:true
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/archivo/reportesview/:id?',
        name: 'administracion-archivo-reportesview',
        component: () => import('@/views/sistema/administracion/archivo/reportes/ReportesView.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.administracion_archivo_cliente, 
        },
        beforeEnter: ValidarPermiso,
    },,


    
    {
        path: '/administracion/archivo/configuracion',
        name: 'administracion-archivo-configuracion',
        component: () => import('@/views/sistema/administracion/archivo/configuracion/Configuracion.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_archivo_configuracion
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/archivo/consultasql',
        name: 'administracion-archivo-consultasql',
        component: () => import('@/views/sistema/administracion/archivo/consultas-sql/ConsultaSql.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_archivo_configuracion
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/archivo/etiqueta',
        name: 'administracion-archivo-etiqueta',
        component: () => import('@/views/sistema/administracion/archivo/etiquetas/Etiqueta.vue'),
        meta: {
            requiresAuth: true,

        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/locatario/procesar',
        name: 'administracion-locatario-procesar',
        component: () => import('@/views/sistema/administracion/locatarios/procesar/Procesar.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_locatario_procesar
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/propina/procesar',
        name: 'administracion-propina-procesar',
        component: () => import('@/views/sistema/administracion/propina/procesar/Procesar.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_ventas_cxc_procesar
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/propina/reporte/por-fecha',
        name: 'administracion-propina-reposte-por-fecha',
        component: () => import('@/views/sistema/administracion/propina/reportes/por-fechas/PorFechas.vue'),
        meta: {
            requiresAuth: true,
           // modulo: modulo.administracion_propina_reposte_por_fecha
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/banco-caja/banco',
        name: 'administracion-banco-caja-banco',
        component: () => import('@/views/sistema/administracion/banco-caja/banco/Banco.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_banco_caja_banco
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/banco-caja/caja',
        name: 'administracion-banco-caja-caja',
        component: () => import('@/views/sistema/administracion/banco-caja/caja/Caja.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_banco_caja_caja
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/banco-caja/tipo-cuenta',
        name: 'administracion-banco-caja-tipo-cuenta',
        component: () => import('@/views/sistema/administracion/banco-caja/tipo-cuenta/TipoCuenta.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_banco_caja_tipo_cuenta
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/banco-caja/tipo-pago',
        name: 'administracion-banco-caja-tipo-pago',
        component: () => import('@/views/sistema/administracion/banco-caja/tipo-pago/TipoPago.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_banco_caja_tipo_pago
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/banco-caja/punto-venta',
        name: 'administracion-banco-caja-punto-venta',
        component: () => import('@/views/sistema/administracion/banco-caja/punto-venta/PuntoVenta.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_banco_caja_punto_venta
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/banco-caja/cuenta-bancaria',
        name: 'administracion-banco-caja-cuenta-bancaria',
        component: () => import('@/views/sistema/administracion/banco-caja/cuenta-bancaria/CuentaBancaria.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_banco_caja_cuenta_bancaria
        },
        beforeEnter: ValidarPermiso,
    },

    {
        path: '/administracion/propina/reporte/por-fecha',
        name: 'administracion-propina-reposte-por-fecha',
        component: () => import('@/views/sistema/administracion/propina/reportes/por-fechas/PorFechas.vue'),
        meta: {
            requiresAuth: true,
            ///modulo: modulo.administracion_propina_reposte_por_fecha
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/locatario/reportes/cuenta-global',
        name: 'administracion-locatario-reportes-cuenta-global',
        component: () => import('@/views/sistema/administracion/locatarios/reportes/cuenta-global/CuentaGlobal.vue'),
        meta: {
            requiresAuth: true,
           // modulo: modulo.administracion_locatario_reportes_cuenta_global
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/ventas/cxc/dashboard',
        name: 'administracion-ventas-cxc-dashboard',
        component: () => import('@/views/sistema/administracion/ventas/cuentaxcobrar/dashborad/Dashboard.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_ventas_cxc_dashboard
        },
        beforeEnter: ValidarPermiso,
    }
    ,
    {
        path: '/administracion/ventas/cxc/reporte/cuentas-global',
        name: 'administracion-ventas-cxc-reporte-cuentas-global',
        component: () => import('@/views/sistema/administracion/ventas/cuentaxcobrar/reporte/cuenta-global/CuentaGlobal.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.administracion_ventas_cxc_reporte_cuentas_global
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/ventas/cxc/reporte/cuentas-detallado',
        name: 'administracion-ventas-cxc-reporte-cuentas-detallado',
        component: () => import('@/views/sistema/administracion/ventas/cuentaxcobrar/reporte/cuenta-detalles/CuentaDetallado.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.administracion_ventas_cxc_reporte_cuentas_detallado
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/ventas/cxc/reporte/pago-tipo-pago',
        name: 'administracion-ventas-cxc-reporte-pago-tipo-pago',
        component: () => import('@/views/sistema/administracion/ventas/cuentaxcobrar/reporte/pago-tipo-pago/PagoTipoPago.vue'),
        meta: {
            requiresAuth: true,
          //  modulo: modulo.administracion_ventas_reporte_tipo_pago
        },
        beforeEnter: ValidarPermiso,
    }, {
        path: '/administracion/ventas/cxc/procesar',
        name: 'administracion-ventas-cxc-procesar',
        component: () => import('@/views/sistema/administracion/ventas/cuentaxcobrar/Procesar.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_ventas_cxc_procesar
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/ventas/reporte/general',
        name: 'administracion-ventas-reporte-general',
        component: () => import('@/views/sistema/administracion/ventas/reportes/general/General.vue'),
        meta: {
            requiresAuth: true,
           // modulo: modulo.administracion_ventas_reporte_general
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/ventas/reporte/zetas',
        name: 'administracion-ventas-reporte-zetas',
        component: () => import('@/views/sistema/administracion/ventas/reportes/zetas/Zetas.vue'),
        meta: {
            requiresAuth: true,
           // modulo: modulo.administracion_ventas_reporte_zetas
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/ventas/reporte/pripinas',
        name: 'administracion-ventas-reporte-propinas',
        component: () => import('@/views/sistema/administracion/ventas/reportes/propinas/Propinas.vue'),
        meta: {
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/ventas/reporte/arqueo',
        name: 'administracion-ventas-reporte-arqueo',
        component: () => import('@/views/sistema/administracion/ventas/reportes/arqueo-caja/ArqueoCaja.vue'),
        meta: {
            requiresAuth: true,
           // modulo: modulo.administracion_ventas_reporte_arqueo
        },
        beforeEnter: ValidarPermiso,
    }, {
        path: '/administracion/ventas/reporte/tasasdias',
        name: 'administracion-ventas-reporte-tasas-dias',
        component: () => import('@/views/sistema/administracion/ventas/reportes/tasas-dias/TasasDias.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.administracion_ventas_reporte_arqueo
        },
        beforeEnter: ValidarPermiso,
    }, {
        path: '/administracion/ventas/reporte/relaciongrupocaja',
        name: 'administracion-ventas-reporte-relacion-grupo-caja',
        component: () => import('@/views/sistema/administracion/ventas/reportes/relacion-grupo-caja/RelacionGrupoCaja.vue'),
        meta: {
            requiresAuth: true,
           // modulo: modulo.administracion_ventas_reporte_arqueo
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/ventas/reporte/ventas-por-producto',
        name: 'administracion-ventas-reporte-ventas-por-producto',
        component: () => import('@/views/sistema/administracion/ventas/reportes/ventas-por-producto/VentasXProducto.vue'),
        meta: {
            requiresAuth: true,
           // modulo: modulo.administracion_ventas_reporte_ventas_por_producto
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/ventas/reporte/tipo-pago',
        name: 'administracion-ventas-reporte-tipo-pago',
        component: () => import('@/views/sistema/administracion/ventas/reportes/tipo-pago/TipoPago.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.administracion_ventas_reporte_tipo_pago
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/ventas/reporte/horas',
        name: 'administracion-ventas-reporte-fechas',
        component: () => import('@/views/sistema/administracion/ventas/reportes/horas/Horas.vue'),
        meta: {
            requiresAuth: true,
           // modulo: modulo.administracion_ventas_reporte_fechas
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/ventas/reporte/ComandasPorProducto',
        name: 'administracion-ventas-reporte-comandasporproducto',
        component: () => import('@/views/sistema/administracion/ventas/reportes/comandas-por-producto/ComandasPorProducto.vue'),
        meta: {
            requiresAuth: true,
          //  modulo: modulo.administracion_ventas_reporte_comandasporproducto
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/ventas/reporte/librocomandas',
        name: 'administracion-ventas-reporte-libro-comandas',
        component: () => import('@/views/sistema/administracion/ventas/reportes/libro-comandas/LibroComandas.vue'),
        meta: {
            requiresAuth: true,
           // modulo: modulo.administracion_ventas_reporte_libro_comandas
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/ventas/reporte/mesonero',
        name: 'administracion-ventas-reporte-mesonero',
        component: () => import('@/views/sistema/administracion/ventas/reportes/mesoneros/Mesoneros.vue'),
        meta: {
            requiresAuth: true,
           // modulo: modulo.administracion_ventas_reporte_mesonero
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/ventas/reporte/mesonero-comision',
        name: 'administracion-ventas-reporte-mesonero-comision',
        component: () => import('@/views/sistema/administracion/ventas/reportes/mesoneros-comision/MesonerosComision.vue'),
        meta: {
            requiresAuth: true,
           // modulo: modulo.administracion_ventas_reporte_mesonero
        },
        beforeEnter: ValidarPermiso,
    },

    {
        path: '/administracion/pagos/concepto',
        name: 'administracion-pagos-concepto',
        component: () => import('@/views/sistema/administracion/pagos/concepto/Concepto.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_pagos_concepto
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/pagos/factura',
        name: 'administracion-pagos-factura',
        component: () => import('@/views/sistema/administracion/pagos/factura/Factura.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_pagos_factura
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/administracion/pagos/proveedor',
        name: 'administracion-pagos-proveedor',
        component: () => import('@/views/sistema/administracion/pagos/proveedor/Proveedor.vue'),
        meta: {
            requiresAuth: true,
            modulo: modulo.administracion_pagos_proveedor
        },
        beforeEnter: ValidarPermiso,
    },

    {
        path: '/administracion/pagos/reporte/concepto',
        name: 'administracion-pagos-reporte-concepto',
        component: () => import('@/views/sistema/administracion/pagos/reporte/conceptos/Conceptos.vue'),
        meta: {
            requiresAuth: true,
            //modulo: modulo.reporte
        },
        beforeEnter: ValidarPermiso,
    },
    {
        path: '/reportes',
        name: 'reportes',
        component: () => import('@/views/sistema/reportes/Reportes.vue'),
        meta: {
            label: "Reportes",
            icon: "Reportes",
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    },

]

const contabilidad = [
    {
        path: '/contabilidad/otromovimiento',
        name: 'contabilidad-otros-movimientos',
        component: () => import('@/views/sistema/contabilidad/otros-movimientos/OtrosMovimientos.vue'),
        meta: {

            requiresAuth: true,
           // modulo: modulo.contabilidad_configuracion_cuenta_contable
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/contabilidad/configuracion/cuenta/contable',
        name: 'contabilidad-configuracion-cuenta-contable',
        component: () => import('@/views/sistema/contabilidad/configuracion/cuenta-contable/CuentaContable.vue'),
        meta: {

            requiresAuth: true,
            modulo: modulo.contabilidad_configuracion_cuenta_contable
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/contabilidad/configuracion/centro/costo',
        name: 'contabilidad-configuracion-centro-costo',
        component: () => import('@/views/sistema/contabilidad/configuracion/centro-costo/CentroCosto.vue'),
        meta: {

            requiresAuth: true,
            modulo: modulo.contabilidad_configuracion_centro_costo
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/contabilidad/reportes/flujo-caja',
        name: 'contabilidad-reporte-flujo-caja-general',
        component: () => import('@/views/sistema/contabilidad/reporte/flujo-caja/FlujoCaja.vue'),
        meta: {

            requiresAuth: true,
            //modulo: modulo.contabilidad_configuracion_centro_costo
        },
        beforeEnter: ValidarPermiso
    }


]
const plantilla = [

    {
        path: '/typography',
        name: 'typography',
        component: () => import('@/views/typography/Typography.vue'),
    },
    {
        path: '/icons',
        name: 'icons',
        component: () => import('@/views/icons/Icons.vue'),
    },
    {
        path: '/cards',
        name: 'cards',
        component: () => import('@/views/cards/Card.vue'),
    },
    {
        path: '/simple-table',
        name: 'simple-table',
        component: () => import('@/views/simple-table/SimpleTable.vue'),
    },
    {
        path: '/form-layouts',
        name: 'form-layouts',
        component: () => import('@/views/form-layouts/FormLayouts.vue'),
    },
    {
        path: '/pages/account-settings',
        name: 'pages-account-settings',
        component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    },
]
const login = [

    {
        path: '/pages/login',
        name: 'pages-login',
        component: () => import('@/views/pages/Login.vue'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/pages/register',
        name: 'pages-register',
        component: () => import('@/views/pages/Register.vue'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/pages/llamar',
        name: 'pages-llamar',
        component: () => import('@/views/pages/LlamarMesonero.vue'),
        meta: {
            layout: 'blank',
        },
    },
    
    {
        path: '/visor/factura',
        name: 'visor-factura',
        component: () => import('@/views/pages/VisorFactura.vue'),
        meta: {
            layout: 'blank',
        },
    },
    
];
const erores = [
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/Error.vue'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '*',
        redirect: 'error-404',
    },
]

const routes = [].concat(
    administracion,
    erores,
    plantilla,
    login,
    general,
    comanda,
    puesto,
    inventario,
    contabilidad
)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes

})

export default router

<template>
  <span>
    <v-menu
      :close-on-content-click="false"
      content-class="user-profile-menu-content"
      max-width="400"
      :min-width="$vuetify.breakpoint.smAndDown ? '100%' : '400'"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" class="ms-2">
          <v-badge
            top
            :color="items.filter(it => it.id_estado == 76).length > 0 ? 'success' : 'secondary'"
            overlap
            dot
            :value="items.length > 0 ? items.length : ''"
          >
            <v-icon>
              {{ icons.mdiBellOutline }}
            </v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-card>
        <div class="pb-3 pt-2">
          <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
            <h3 class="text--primary mb-n1">Notificaciones</h3>
          </div>
        </div>
        <v-divider></v-divider>
        <div style="max-height: calc(100vh - 100px); overflow-y: auto">
          <!-- Logout -->

          <v-card rounded="0" v-for="(item, index) in items" :key="index" class="mx-auto mb-1" @click="0 == 0">
            <v-card-title class="my-0 pb-1">
              <v-icon left :color="item.color" @click="abrirNotificacion(item)"> {{ icons.mdiBellOutline }} </v-icon>
              <small
                class="font-weight-light"
                :class="item.color ? `${item.color}--text` : ''"
                @click="abrirNotificacion(item)"
                >{{ item.titulo }}</small
              >
              <v-row align="center" justify="end">
                <v-btn icon @click="LeerNotificacion(item)" x-small>
                  <v-icon small v-if="item.id_estado == 76"> {{ icons.mdiCheck }} </v-icon>
                  <v-icon small v-else> {{ icons.mdiCheckAll }} </v-icon>
                  {{ item.id_estado }}
                </v-btn>
                <v-btn icon @click="cerrarNotificacion(item)" x-small>
                  <v-icon small> {{ icons.mdiClose }} </v-icon>
                </v-btn>
              </v-row>
            </v-card-title>

            <v-divider @click="abrirNotificacion(item)" class="mx-4 mt-2"></v-divider>
            <v-card-text class="my-0 pb-1" @click="abrirNotificacion(item)">
              {{ item.sub_titulo }}
            </v-card-text>

            <v-card-actions @click="abrirNotificacion(item)">
              <strong class="text--secondary"
                ><small> {{ item.autor }}</small></strong
              >
              <v-row align="center" justify="end">
                <strong class="text--secondary"
                  ><small> {{ funciones.formatoFecha(item.fecha_creacion, 3) }}</small></strong
                >
              </v-row>
            </v-card-actions>
          </v-card>

          <v-card v-if="items.length == 0">
            <v-card-text> No hay notificaciones </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-menu>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card  v-if="alertaNew.datos">
        <v-card-title :class="alertaNew.color" class="text-h5 white--text text-center"> <span class="white--text">{{ alertaNew.titulo }}</span> </v-card-title>
        <v-card-text :class="alertaNew.color" class="white--text text-center">
          <v-icon size="50" color="white">{{ icons.mdiAlert }}</v-icon>
          </v-card-text>
          <v-divider></v-divider>
        <v-card-text class="  text-center">
         
          <p>{{  alertaNew.datos.empleado.nombre_empleado}}  {{  alertaNew.datos.empleado.apellido_empleado}} </p>
          <h3 class=" text-center">
         <span  > {{ alertaNew.sub_titulo }}</span>  

          </h3>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="text-center">
          <v-btn color="secondary" text class="white--text mr-1" @click="cancelarAlerta()"> Cancelar </v-btn>
          <v-btn color="primary" text class="white--text" @click="aceptarAlerta()"> Aceptar </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiKey,
  mdiBellOutline,
  mdiCloseCircle,
  mdiClose,
  mdiCheck,
  mdiCheckAll,
  mdiAlert,
  mdiEmailFastOutline
} from '@mdi/js'
import store from '@/store'
import router from '@/router'
import config from '@/api/config'
import CambioClave from '@/views/sistema/administracion/archivo/empleado/CambioClave.vue'
import { ref, watch } from '@vue/composition-api'
import { socket, state as stateSocket } from '@/plugins/sockets'
import funciones from '@/funciones/funciones'
export default {
  components: {
    CambioClave,
  },

  watch: {
    dialog: function (_new, _old) {
      if (_new != _old) {
        if (_new == true) {
          try {
            Android.vibrar()
          } catch (e) {
            console.log(e)
          }
        } else {
          try {
            Android.stopVibrar()
          } catch (e) {
            console.log(e)
          }
        }
      }
    },

    notificacion_nueva: function (_new, _old) {
      if (_new != null && JSON.stringify(_new) != JSON.stringify(_old)) {
        console.log('notificacion_nueva', _new)
        const notification = {
          title: _new.titulo,
          options: {
            body: _new.sub_titulo,
          },
          events: {
            onerror: function () {
              console.log('Custom error event was called')
            },
            onclick: () => {
              this.abrirNotificacion(_new)
            },

            onclose: function () {
              console.log('close')
            },
            onshow: function () {
              console.log('Custom show event was called')
            },
          },
        }
        this.$notification.show(notification.title, notification.options, notification.events)
        this.dialog = true
        try {
          Android.showToast(_new.titulo, _new.sub_titulo)
        } catch (err) {
          console.log(err)
        }
      }
    },
  },
  setup(props, context) {
    const dialog = ref(false)
    const mdiFaceMan =
      'M9,11.75A1.25,1.25 0 0,0 7.75,13A1.25,1.25 0 0,0 9,14.25A1.25,1.25 0 0,0 10.25,13A1.25,1.25 0 0,0 9,11.75M15,11.75A1.25,1.25 0 0,0 13.75,13A1.25,1.25 0 0,0 15,14.25A1.25,1.25 0 0,0 16.25,13A1.25,1.25 0 0,0 15,11.75M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,11.71 4,11.42 4.05,11.14C6.41,10.09 8.28,8.16 9.26,5.77C11.07,8.33 14.05,10 17.42,10C18.2,10 18.95,9.91 19.67,9.74C19.88,10.45 20,11.21 20,12C20,16.41 16.41,20 12,20Z'
    const notificacion_nueva = ref(null)
    const items = ref([
      /* {
        id: 'asdqqwqwqw12',
        tipo_avatar: 'img', //'img','icon','letra'
        avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
        titulo: 'Autorizacion de clave',
        sub_titulo: `Se esta solicitando la atorixacion para realizar la accion: Modificar Comanda`,
        autor: `Angel Labori`,
        color: '',
        datos: {},
      } */
    ])

    const alertaNew = ref({})

    socket.on('notificacion', args => {
      console.log('notificacion', args)

      args.id_estado = 76
      items.value.unshift(args)
      EntregadaNotificacion(args.id)
      notificacion_nueva.value = args

      if (args.tipo_accion == 'alerta') {
        alertaNew.value = args
        dialog.value = true
      } 
    })

    const aceptarAlerta = ()=>{
      dialog.value = false
      abrirNotificacion(alertaNew.value)
    }

    const cancelarAlerta = ()=>{
      dialog.value = false
      cerrarNotificacion(alertaNew.value)

    }
    socket.on('alert-confirmado-otro', args => {
    
      if (alertaNew.value.datos.idTran == args.datos.datos.idTran && dialog.value == true) {
   
        dialog.value = false
        items.value = items.value.filter(item => item.id != args.datos.id)
        store.commit('setAlert', {
          message: `La solicitud fue atendida por el usuario: ${args.datos.empleado.nombre} ${args.datos.empleado.apellido}`,
          type: 'info',
        })
      }
     
    })

    socket.on('notificacion_all', args => {
      items.value = args
      console.log('notificacion_all', args)
      var arrynotyfy = []
      args
        .filter(item => item.id_estado == 75)
        .forEach(element => {
          arrynotyfy.push(element.id)
          element.id_estado = 76
        })

      if (arrynotyfy.length > 0) {
        EntregadaNotificacion(arrynotyfy.join())
      }
    })

    const cerrarNotificacion = _item => {
      socket.emit('notificacion_actualizar', { notificacion: `${_item.id}`, id_estado: 79 })

      items.value = items.value.filter(item => item.id != _item.id)
    }
    const abrirNotificacion = _item => {
      socket.emit('notificacion_actualizar', { notificacion: `${_item.id}`, id_estado: 78, id_usuario: store.state.user.id })

      if (_item.tipo_accion == 'autorizacion') {
        context.emit('GetConfirmacionUsuario', _item.datos)
      }
      
      items.value = items.value.filter(item => item.id != _item.id)
    }

    const LeerNotificacion = _item => {
      if (_item.id_estado == 76) {
        socket.emit('notificacion_actualizar', { notificacion: `${_item.id}`, id_estado: 77 })
        _item.id_estado = 77
      } else {
        socket.emit('notificacion_actualizar', { notificacion: `${_item.id}`, id_estado: 76 })
        _item.id_estado = 76
      }
    }

    const EntregadaNotificacion = _item => {
      socket.emit('notificacion_actualizar', { notificacion: `${_item}`, id_estado: 76 })
    }

    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiKey,
        mdiFaceMan,
        mdiBellOutline,
        mdiCloseCircle,
        mdiClose,
        mdiCheck,
        mdiCheckAll,
        mdiAlert,
        mdiEmailFastOutline
      },
      store,
      config,
      items,
      cerrarNotificacion,
      abrirNotificacion,
      LeerNotificacion,
      funciones,
      notificacion_nueva,
      dialog,
      aceptarAlerta,
      cancelarAlerta,
      alertaNew
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>

<template>
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" width="500"  >
        <template>
            <v-card> 
                <v-card-text>

                    <v-img 
                        src="@/assets/images/wifi000001.jpg"
                        aspect-ratio="1" cover width="500"
                        height="1000"
                        class="bg-grey-lighten-2">
                    </v-img>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn variant="text" @click="dialog = false">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>
<script>
import { ref } from '@vue/composition-api';

export default {
    setup() {
        const dialog = ref(false)


        const abrir = () => {
            dialog.value = true
        }
        return {
            dialog,
            abrir
        }
    },
}
</script>
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-croppa/dist/vue-croppa.css';
import excel from 'vue-excel-export'
import Croppa from 'vue-croppa'; 
import "./plugins/vuetify-mask";
import loader from "vue-ui-preloader"; 
import VueApexCharts from 'vue-apexcharts'   
import "./plugins/vue-native-notification";


Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false
Vue.use(VueSweetalert2)
Vue.use(Croppa); 
Vue.use(excel)
Vue.use(loader);
Vue.use(VueApexCharts);   
new Vue({
  router,
  store,
  vuetify,
  excel,
  loader, 
  render: h => h(App),
}).$mount('#app')
